import { get } from 'lodash';

import * as api from 'services/api';
import { types as summaryTypes } from 'store/reducers/summary.reducer';
import { types as campaignTypes } from 'store/reducers/campaign.reducer';

export const types = {
  ON_MOUNT: 'ON_MOUNT',
};

const typeMap = {
  home: summaryTypes.ON_SUMMARY,
  campaign: campaignTypes.ON_DATA,
};

/**
 * home page mount
 * @param {*} state
 */
const homeMountHandler = async state => {
  // if exists ... read & return from store
  const { summary } = state.getState();
  if (Object.keys(summary).length) return summary;
  // ... else request
  const data = await api.accountSummary();
  return data.data;
};

/**
 * campaign page mount
 * @param {*} state
 * @param {*} payload: action payload with endpointsByRole
 */
const campaignMountHandler = async (state, payload) => {
  const { campaign } = state.getState();
  const { endpointsByRole } = payload;
  // let {kpis, timeframe, groups} = campaign

  // get stored data or request from store
  const kpis = campaign.kpis || (await api.filterKpis());
  const timeframe = campaign.timeframe || (await api.filterTimeframe());
  let {
    groups,
    rawGroups,
    groupsOptions,
    campaignsOptions,
    groupFilterInfo,
    tutteId = '',
  } = campaign;
  // let tutteId = ''

  if (!groups) {
    rawGroups = await api.filterGroups(endpointsByRole);
    // groups = rawGroups.data.filter(group => {
    //   // remove empty
    //   const hasCampaigns = !!get(group, 'subgroups[0].campaigns[0]');
    //   // !hasCampaigns && console.log(group.id, group.name, hasCampaigns)
    //   return hasCampaigns;
    // });

    groups = rawGroups.data;

    groupsOptions = groups.reduce((acc, item) => {
      const { id, name, subgroups } = item;
      const option = {
        id,
        name: `${name} (${subgroups.length})`,
        className: 'group-option',
      };
      // NOTE: added _ to id, because of the id duplication in data from server
      const subOptions = subgroups.map(({ id, name }) => ({
        id: `_${id}`,
        name,
        className: 'subgroup-option',
      }));
      return [...acc, option, ...subOptions];
    }, []);

    campaignsOptions = [];
    groupFilterInfo = new Map();
    groups.forEach(group => {
      const { subgroups, id: groupId } = group;
      // const temp = {}
      // const subTemp = {}

      // temp[groupId] = group.name

      tutteId = `${tutteId},${groupId}`;

      groupFilterInfo.set(groupId, group.name);

      subgroups.forEach(sub => {
        const { campaigns, id: subgroupId } = sub;

        // subTemp[subgroupId]= sub.name
        // temp[groupId] = `${temp[groupId]}_${sub.name}`

        groupFilterInfo.set(subgroupId, sub.name);
        groupFilterInfo.set(
          groupId,
          `${groupFilterInfo.get(groupId)}, ${sub.name}`
        );

        // const updatedCampaigns = campaigns.map(camp => ({
        //   ...camp,
        //   groupId,
        //   subgroupId,
        // }));
        // campaignsOptions = [...campaignsOptions, ...updatedCampaigns];
      });

      // groupFilterInfo = {...groupFilterInfo, ...temp, ...subTemp}
    });
  }
  // BUG: why?
  tutteId = tutteId.indexOf(',') === 0 ? tutteId.substring(1) : tutteId;

  // console.log('OUT...');
  // console.log({ groupsOptions });

  return {
    kpis,
    timeframe,
    groups,
    rawGroups,
    groupsOptions,
    campaignsOptions,
    groupFilterInfo,
    tutteId,
  };
};

const handlers = {
  home: homeMountHandler,
  campaign: campaignMountHandler,
};

/**
 * on component mount middleware
 * @param {*} state
 */
export const onMountMiddleware = state => next => async action => {
  if (action.type === types.ON_MOUNT) {
    try {
      let { page = '' } = action.payload;
      page = page.trim(); //.toUpperCase()
      // asyn action for
      const f = handlers[page];
      const data = await f(state, action.payload);
      // console.log('@-->', data);
      return next({
        type: typeMap[page] /*  `${action.type}_${page}` */,
        payload: data,
      });
    } catch (error) {
      // console.log('onMountMiddleware:', {error})
    }
  }
  next(action);
};
