import React from 'react';
import styled from 'styled-components';

const Bar = styled.progress`
  width: 25%;
  min-width: 200px;
  margin: 16px auto;
  background-image: linear-gradient(
    90deg,
    rgb(200, 200, 200) 30%,
    rgb(237, 237, 237) 30%
  ) !important;
`;

const ProgressBar = () => (
  <Bar className="progress is-small" max="100">
    loading
  </Bar>
);
export default ProgressBar;
