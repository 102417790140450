import React from 'react'
import {SelectPicker} from 'rsuite'

/** SelectPicker wrapper */
function Select({
  data, 
  value, 
  changeHandler, 
  cleanHandler = () => {}, 
  defaultValue = '', 
  ...otherProps
}) { 
  return (
    <SelectPicker
      className="field-select"
      data={data}
      defaultValue={defaultValue}
      value={value}
      placement="auto"
      onChange={changeHandler}
      onClean={cleanHandler}
      size="md"
      renderMenuItem={(label, item) => {
        return <span style={{
          fontWeight: (item.className === 'group-option') ? 700 : 500,
          paddingLeft: (item.className === 'group-option') ? 0 : 8,
        }}>{label}</span>
      } }
      {...otherProps} 
    />
  )
}

export default Select
