import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body {
  font-size: 16px;
}
  .box {
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,0.25), 0 0 0 1px rgba(10,10,10,.02);
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    _padding-top: 10px;
    _padding-bottom: 9px;
  }
  .rs-table-row-header .rs-table-cell {
    background-color: ${props => props.colors.blue} !important;
    color: #fff;
    font-weight: 700;
  }
  .rs-table-cell-header-icon-sort,
  .rs-table-cell-header-icon-sort-desc::after,
  .rs-table-cell-header-icon-sort-asc::after {
    color: #fff;
  }
  .rs-table-cell-header .rs-table-cell-content {
    font-size: 1rem !important;
    line-height: 1 !important;
  }
  .image > img {
    height: auto;
  }
  .has-blue-bg {
    background-color: ${props => props.colors.blue}
  }
  .has-grey-bg {
    background-color: ${props => props.colors.greyBlue}
  }
  .print-icon {
    cursor: pointer;
    transform: scale(1.75);
  }
  .link-disabled {
    pointer-events: none;
    opacity: 0.25;
  }
  .sized-ul {
    max-height: 220px;
    overflow-y: auto;
  }
  .simulate-btn {
    _background-color: purple;
    font-weight: 700;
    border-radius: 20px;
    min-width: 180px;
  }
  .simulation-section{
    _border: 2px solid rgb(0,0,0,0.125);
    _border-radius: 8px;
    }
  .simulation-section .title{
    _border-radius: 24px;
  }
  .simulation-section .title.alt-title{
    color: rgb(64,80,104) !important;
    background-color: rgb(251, 222, 85) !important;
    _border-radius: 24px;
  }
  .standalonetoggle.react-toggle--checked .react-toggle-track{
    background-color: rgb(50 152 220) !important;
  }
  .special-radio-reset .radio {
    margin-left: 0 !important; 
  }
  .DataInputForm .radio {
    margin-bottom: 1rem;
  }
  .input.is-outlined{
    outline: 2px solid ${props => props.colors.blue};
  }
  img.tableThumb{
    display: block;
    object-fit:scale-down;
    width: 300px;
    height: 300px;
  }
  .tableRowClassName{
    _height: 128px !important;
  }
`;
