import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AiOutlineLineChart, AiOutlineBarChart } from 'react-icons/ai';

import { useI18n } from 'contexts/I18nProvider';
import EmailButton from 'components/page/EmailButton';

const Wrapper = styled.div`
  color: #fff;

  .is-9 .columns,
  .is-3 .columns {
  }

  .item,
  .use-as-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${props => props.theme.colors.blue};
    border-radius: ${props => props.theme.border.radius};

    p {
      flex-grow: 1;
    }

    .col-title {
    }

    .button {
      background-color: ${props => props.theme.colors.green};
      border: 0 none;
      color: #fff;
    }
  }
`;

const Callouts = () => {
  const { messages } = useI18n();

  return (
    <Wrapper className="columns">
      <div className="column is-8 p-3">
        <div className="item p-3">
          <div className="columns">
            <div className="column use-as-flex">
              <div className="col-title is-size-4 mb-4 has-text-weight-bold">
                {messages.dashboardSubTitle1String}
              </div>
              <p className="mb-3">{messages.dashboardSubDescription1String}</p>
              <Link
                to="/overview-performance"
                className="button has-text-white"
              >
                <span className="icon mr-3">
                  <AiOutlineLineChart />
                </span>
                {messages.dashboardSubButton1String}
              </Link>
            </div>
            <div className="column">
              <img src="/images/Grafico_HomeCampagne.png" alt="grafico" />
            </div>
          </div>
        </div>
      </div>
      <div className="column is-4 p-3">
        <div className="item p-3">
          <div className="col-title is-size-4 mb-4 has-text-weight-bold">
            {messages.dashboardSubTitle2String}
          </div>
          <p className="mb-3">{messages.dashboardSubDescription2String}</p>
          <Link to="/campaign_simulator" className="button has-text-white">
            {messages.dashboardSubButton2String}
          </Link>
          {/* <EmailButton>
            <span className="icon mr-3"><AiOutlineBarChart/></span>
            {messages.dashboardSubButton2String}
          </EmailButton> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default Callouts;
