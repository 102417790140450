import {createReducer} from '@reduxjs/toolkit'

import {types as lifecycleTypes} from 'store/reducers/types'

const initialValues = {}

export const types = {
  ON_SUMMARY: 'ON_SUMMARY'
}

export default createReducer(initialValues, {
  ON_SUMMARY: (state, action) => action.payload,
  [lifecycleTypes.ON_LOGOUT]: (state, action) => initialValues
})