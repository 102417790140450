import React, { useState } from 'react';
import { Table } from 'rsuite';
import { sortBy, reverse } from 'lodash';

import NoDataMessage from 'components/page/NoDataMessage';
import { useI18n } from 'contexts/I18nProvider';
import { FaUserPlus } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi';

const LinkCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <a href={`${rowData[dataKey]}`} target="_blank">
      <BiLinkExternal size="24px" />
    </a>
  </Cell>
);

const { Cell, Column, HeaderCell, Pagination } = Table;

const View = ({ data, loading, limit = 6 }) => {
  const [_sortType, toggleType] = useState('desc');
  const [_sortColumn, setCol] = useState('total');
  const { messages, pagination } = useI18n();
  const [page, setPage] = useState(1);
  const [displayLength, setDisplayLength] = useState(pagination);

  let sortedDataTable = sortBy(data, [_sortColumn]);
  if (_sortType === 'desc') sortedDataTable = reverse(sortedDataTable);

  const paginatedData = sortedDataTable.filter((v, i) => {
    const start = displayLength * (page - 1);
    const end = start + displayLength;
    return i >= start && i < end;
  });

  const handleChangePage = eventKey => {
    setPage(eventKey);
  };

  // HANDLE SHOW OFFLINE CLICK ONLY FOR SUPERADMIN - USING USER GROUP
  let which = JSON.parse(localStorage.getItem('rankit-app-user'));

  return (
    <div key={_sortType}>
      <Table
        data={paginatedData}
        autoHeight
        loading={loading}
        renderEmpty={() => <NoDataMessage />}
        onSortColumn={(sortColumn, sortType) => {
          toggleType(_sortType === 'asc' ? 'desc' : 'asc');
          setCol(sortColumn);
        }}
        sortType={_sortType}
        sortColumn={_sortColumn}
      >
        {/* Brand = result.brand = tableBrandString
        Campagna = result.campaign = tableCampaignString
        Post = result.post = tablePostString
        Hash: result.hash = tableHashString
        Total Click = result.total = tableTotalClickString
        Link = https://taply.link/l/gossip/{result.hash}/ (target = new) = tableLinkString */}

        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableBrandString}</HeaderCell>
          <Cell dataKey="brand" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableCampaignString}</HeaderCell>
          <Cell dataKey="campaign" />
        </Column>
        <Column flexGrow={2} sortable>
          <HeaderCell>{messages.tablePostString}</HeaderCell>
          <Cell dataKey="post" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableHashString}</HeaderCell>
          <Cell dataKey="hash" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableDriverString}</HeaderCell>
          <Cell dataKey="driver" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableTotalClickString}</HeaderCell>
          <Cell dataKey="total" />
        </Column>
        <Column width={80} sortable>
          <HeaderCell>{messages.tableLinkString}</HeaderCell>
          {/* <Cell dataKey="hash" /> */}
          <LinkCell dataKey="link" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          size="xs"
          lengthMenu={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
          ]}
          total={sortedDataTable.length}
          displayLength={displayLength}
          activePage={page}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  );
};

export default View;
