import React from 'react'
import ReactDOM from 'react-dom'
// import {ReactQueryDevtools} from 'react-query-devtools'

import StoreProvider from './store/StoreProvider'
import App from './App';
import 'utils/axiosConfig'
import 'utils/numeralConfig'
import 'utils/chartConfig'
// import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

ReactDOM.render(
  <StoreProvider>
    <App />
    {/* <ReactQueryDevtools /> */}
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
