import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  AiOutlineLineChart,
  AiFillInstagram,
  AiFillEdit,
} from 'react-icons/ai';
import { CgCommunity } from 'react-icons/cg';
import { BiHeartCircle, BiGroup } from 'react-icons/bi';
import { FaUserPlus, FaShoppingCart } from 'react-icons/fa';

import { useI18n } from 'contexts/I18nProvider';
import Bubble from './Bubble';

const Wrapper = styled.div`
  color: #fff;

  .item {
    height: 100%;
    border-radius: ${props => props.theme.border.radius};
    padding: ${props => props.theme.gap};
    color: #fff;

    a {
      color: #fff;
    }

    &.analyze {
      display: flex;
      line-height: 1.125;

      .is-128x128 {
        height: auto;

        img {
          max-width: 100%;
        }
      }
    }
  }

  /* .is-4,
  .was-4 { */
  .item.instagram {
    background-color: ${props => props.theme.colors.black};
  }
  .item.tiktok {
    background-color: ${props => props.theme.colors.pink};
  }
  .item.bubble {
    background-color: ${props => props.theme.colors.blue};
  }
  /* } */

  .is-8,
  .blue-box {
    .item {
      text-align: center;
      background-color: ${props => props.theme.colors.greyBlue};
    }
  }
`;

const data = messages => [
  {
    icon: <AiOutlineLineChart size="32px" />,
    title: messages.rankitSocialOrganicTrafficTitle,
    info: messages.rankitSocialOrganicTrafficDescription,
    key: 0,
  },
  {
    icon: <FaUserPlus size="32px" />,
    title: messages.rankitSocialLeadGenerationTitle,
    info: messages.rankitSocialLeadGenerationDescription,
    key: 1,
  },
  {
    icon: <CgCommunity size="32px" />,
    title: messages.rankitSocialCommunityAmplificationTitle,
    info: messages.rankitSocialCommunityAmplificationDescription,
    key: 2,
  },
];

const data2 = messages => [
  {
    icon: <BiHeartCircle size="32px" />,
    title: messages.rankitSocialCommunityGrowthTitle,
    info: messages.rankitSocialCommunityGrowthDescription,
    key: 3,
  },
  {
    icon: <BiGroup size="32px" />,
    title: messages.rankitSocialCommunityResearchTitle,
    info: messages.rankitSocialCommunityResearchDescription,
    key: 4,
  },
  {
    icon: <AiFillEdit size="32px" />,
    title: messages.rankitBloggerContentFactoryTitle,
    info: messages.rankitBloggerContentFactoryDescription,
    key: 5,
  },
];

const Options = () => {
  const { messages } = useI18n();

  return (
    <Wrapper className="_columns">
      <div className="_column _is-4 _p-3">
        <div className="columns">
          {data(messages).map(item => (
            <div key={`${item.key}`} className="column blue-box">
              <div className="item">
                <span className="icon _mr-3">{item.icon}</span>
                <div className="col-title is-size-4 mb-4 has-text-weight-bold">
                  {item.title}
                </div>
                <p className="mb-3">
                  <small>{item.info}</small>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="_column _is-4 _p-3">
        <div className="columns">
          {data2(messages).map(item => (
            <div key={`${item.key}`} className="column blue-box">
              <div className="item">
                <span className="icon _mr-3">{item.icon}</span>
                <div className="col-title is-size-4 mb-4 has-text-weight-bold">
                  {item.title}
                </div>
                <p className="mb-3">
                  <small>{item.info}</small>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="columns">
          <div className="column">
            <div className="item bubble">
              {/* <div className="col-title is-size-4 mb-4 has-text-weight-bold">{messages.rankitWatcherTitleString}</div>
            <p className="mb-3">{messages.rankitWatcherDescriptionString}</p> */}
              <Bubble />
            </div>
          </div>
          <div className="column">
            <div className="item instagram analyze p-3">
              <div>
                <div>
                  <Link to="/analyze/instagram">
                    <div className="col-title is-size-4 mb-2 has-text-weight-bold">
                      {messages.rankitInstagramTitleString}
                    </div>
                    <small>{messages.rankitInstagramDescriptionString}</small>
                  </Link>
                </div>
              </div>
              <div>
                <div>
                  <figure className="media-right">
                    <div className="image is-128x128">
                      <img
                        src="/images/analyze-instagram-logo-128.jpg"
                        alt="Analyze Instagram"
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="item tiktok analyze p-3">
              <div>
                <div>
                  <Link to="/analyze/tiktok">
                    <div className="col-title is-size-4 mb-2 has-text-weight-bold">
                      {messages.rankitTiktokTitleString}
                    </div>
                    <small>{messages.rankitTiktokDescriptionString}</small>
                  </Link>
                </div>
              </div>
              <div>
                <div>
                  <figure className="media-right">
                    <div className="image is-128x128">
                      <img
                        src="/images/analyze-tiktok-logo-128.jpg"
                        alt="Analyze TikTok"
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="column is-4 p-3">
        <div className="item analyze p-3">
          <div>
            <Link to="/analyze/instagram">
              <div className="col-title is-size-4 mb-2 has-text-weight-bold">{messages.rankitInstagramTitleString}</div>
              <small>{messages.rankitInstagramDescriptionString}</small>
            </Link>
          </div>
          <div>
            <Link to="/analyze/instagram">
              <figure className="media-right">
                <div className="image is-128x128">
                  <img src="/images/analyze-instagram-logo-128.jpg" alt="Analyze Instagram"/>
                </div>
              </figure>
            </Link>
          </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

export default Options;
