import { useEffect, useRef } from 'react';

export function useInterval(mms = 1000 * 60) {
  let interval = useRef();

  const startInterval = fn => {
    if (interval.current) {
      stopInterval();
    }
    interval.current = setInterval(fn, mms);
  };

  const stopInterval = () => {
    clearInterval(interval.current);
  };

  useEffect(() => {
    return () => stopInterval();
  }, []);

  return { startInterval, stopInterval };
}
