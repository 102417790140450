import React, { useState } from 'react';
import { Table } from 'rsuite';
import { sortBy, reverse } from 'lodash';

import NoDataMessage from 'components/page/NoDataMessage';
import { useI18n } from 'contexts/I18nProvider';

const { Cell, Column, HeaderCell } = Table;

const View = ({ data, loading, hasLead, hasReach }) => {
  // const [_sortType, toggleType] = useState('asc');
  // const [_sortColumn, setCol] = useState('brand');
  const { messages } = useI18n();

  // let sortedDataTable = sortBy(data, [_sortColumn]);

  // if (_sortType === 'desc') sortedDataTable = reverse(sortedDataTable);

  return (
    <div>
      <Table
        data={data}
        rowClassName="tableRowClassName"
        autoHeight
        rowHeight={300}
        renderEmpty={() => <NoDataMessage />}
      >
        <Column flexGrow={2}>
          <HeaderCell>{messages.instagramAnalyzeProfileTableImage}</HeaderCell>
          <Cell>
            {rowData => (
              <img
                className="tableThumb"
                src={rowData.image}
                alt="post media"
              />
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>
            {messages.instagramAnalyzeProfileTableComment}
          </HeaderCell>
          <Cell dataKey="commentNumber" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>{messages.instagramAnalyzeProfileTableLike}</HeaderCell>
          <Cell dataKey="likeNumber" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>{messages.instagramAnalyzeProfileTableEr}</HeaderCell>
          <Cell dataKey="er" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>{messages.instagramAnalyzeProfileTableLink}</HeaderCell>
          <Cell dataKey="link">
            {rowData => (
              <a href={rowData.link} target="_blank">
                link
              </a>
            )}
          </Cell>
        </Column>

        {/* <Column flexGrow={1}>
          <HeaderCell>{messages.instagramAnalyzeProfileTableId}</HeaderCell>
          <Cell dataKey="id" />
        </Column> */}
      </Table>
    </div>
  );
};

export default View;
