import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subDays, format } from 'date-fns';
import { isString } from 'lodash';

import { types } from 'store/middlewares/data-filters';
import { TIMEFRAME_DATE_FORMAT } from 'utils/config';
import * as dates from 'utils/dates';
import { useI18n } from './I18nProvider';
import { useSession } from 'contexts/SessionProvider';

/** form default values */
const defaultValues = {
  // param_0: 'some value',
  group: '0',
  campaign: '0',
  kpi: 'click',
  timeframe: [subDays(new Date(), 6), new Date()],
};

/** update timeframe as required from backend */
function updateTimeframe(timeframe = 'last7') {
  if (dates.isLast7(timeframe)) return 'last7';
  if (dates.isLast30(timeframe)) return 'last30';
  if (dates.isYesterday(timeframe)) return 'yesterday';
  if (dates.isToday(timeframe)) return 'today';
  if (dates.isCurrentMonth(timeframe)) return 'currentMonth';
  if (dates.isLastMonth(timeframe)) return 'lastMonth';
  if (dates.isCurrentYear(timeframe) && !dates.isStartOfYear(timeframe))
    return 'currentYear';
  if (dates.isLastYear(timeframe)) return 'lastYear';

  return timeframe
    .map(item => format(new Date(item), TIMEFRAME_DATE_FORMAT))
    .join();
}

function updatePeriodInfo(timeframe = 'last7', messages) {
  // let timeframe = _timeframe;

  // if (timeframe) {
  if (dates.isLast7(timeframe))
    return messages.selectCampaignTimeframeLast7String;
  if (dates.isLast30(timeframe))
    return messages.selectCampaignTimeframeLast30String;
  if (dates.isYesterday(timeframe))
    return messages.selectCampaignTimeframeYesterdayString;
  if (dates.isToday(timeframe))
    return messages.selectCampaignTimeframeTodayString;
  if (dates.isCurrentMonth(timeframe))
    return messages.selectCampaignTimeframeCurrentMonthString;
  if (dates.isLastMonth(timeframe))
    return messages.selectCampaignTimeframeLastMonthString;
  if (dates.isCurrentYear(timeframe))
    return messages.selectCampaignTimeframeCurrentYearString;
  if (dates.isLastYear(timeframe))
    return messages.selectCampaignTimeframeLastYearString;

  return `dal ${format(
    new Date(timeframe[0]),
    TIMEFRAME_DATE_FORMAT
  )} al ${format(new Date(timeframe[1]), TIMEFRAME_DATE_FORMAT)}`;

  //timeframe[0], timeframe[1] timeframe.map(item => format(new Date(item), TIMEFRAME_DATE_FORMAT)).join()
  // }

  // return messages.selectCampaignTimeframeLast7String;
}

/** remove _ from id */
function cleanString(str) {
  return str.replace('_', '');
}

/** the context */
export const CampaignFiltersContext = createContext({
  defaultValues,
  selectedGroup: defaultValues.group,
  setSelectedGroup: () => {},
  dateRanges: [], // _dateRanges(),
  info: {},
  filters: {},
  setFilters: () => {},
});

// NOTE: useEffect per caricare i dati di default delle charts
const CampaignFiltersProvider = ({ children }) => {
  const { messages } = useI18n();
  const { getEndpointsByRole } = useSession();
  const [selectedGroup, _setSelectedGroup] = useState(defaultValues.group);
  const [filters, _setFilters] = useState(defaultValues);
  const [info, setInfo] = useState({});
  const { groupFilterInfo, tutteId } = useSelector(state => state.campaign);
  const dispatch = useDispatch();

  // const _groupOptions = useSelector(
  //   state => get(state, 'campaign.groupsOptions', [])
  // )

  // ########################
  // stop first data fetching
  // ########################
  useEffect(() => {
    // update filters to fetch charts data
    if (isString(tutteId) && tutteId !== '0') setFilters(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutteId]);

  const setSelectedGroup = id => {
    // NOTE: need to clean id
    _setSelectedGroup(cleanString(id));
  };

  const setFilters = (filters /*  = defaultValues */) => {
    // ########################
    // stop first data fetching: is OFF
    // ########################
    if (!filters) return;

    // start routine
    const updatedTimeframe = updateTimeframe(filters.timeframe);
    // NOTE: need to clean id
    const updatedGroup = cleanString(filters.group);

    const updatedFilters = {
      ...filters,
      timeframe: updatedTimeframe,
      group: updatedGroup,
      campaign: defaultValues.campaign, // always use the default
    };
    _setFilters(updatedFilters);

    const period = updatePeriodInfo(filters.timeframe, messages);

    // console.log('--->', {updatedGroup}, {'testo': groupFilterInfo?.get(updatedGroup)})

    // setInfo(`Gruppo: ${updatedGroup === defaultValues.group ? 'Tutte' : groupFilterInfo?.get(updatedGroup)} - Periodo: ${period}`)
    const groupInfo =
      updatedGroup === defaultValues.group
        ? messages.selectCampaignAllString
        : groupFilterInfo?.get(updatedGroup);

    setInfo({ group: groupInfo, timeframe: period, kpi: filters.kpi });

    dispatch({
      type: types.ON_CAMPAIGN_FILTERS_UPDATE,
      payload: { updatedFilters, endpointsByRole: getEndpointsByRole() },
    });
  };

  // const groupOptions = [{id: 0, name: 'Tutte'}, ..._groupOptions]
  const contextValue = {
    defaultValues,
    dateRanges: dates.dateRanges(messages),
    info,
    // groupOptions,
    selectedGroup,
    setSelectedGroup,
    filters,
    setFilters,
  };

  // console.log({ contextValue });
  return (
    <CampaignFiltersContext.Provider value={contextValue}>
      {children}
    </CampaignFiltersContext.Provider>
  );
};

export default CampaignFiltersProvider;

export function useCampaignFiltersContext() {
  const context = useContext(CampaignFiltersContext);
  return context;
}
