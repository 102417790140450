import React from 'react'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'

import {useSession} from 'contexts/SessionProvider'
import SigninForm from './SigninForm'
import PasswordRecoveryForm from './PasswordRecoveryForm'

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #ddd;

  img {
    max-width: 128px;
    height: auto;
  }

  .form-wrapper{
    width: 75%;
    max-width: 480px;

    form {

    }
    img {
      display: block;
      margin: auto;
    }
  }
`

const Login = ({history, location, match}) => {
  const {user} = useSession()
  
  if(user) 
    return <Redirect to="/" />

  return (
    <Wrapper>
      <div className="form-wrapper">
        <img src="/images/Rankit_Logo_Bianco.png" alt="Benvenuto su Rankit"/>
        {location.pathname === '/login' 
          ? <SigninForm/> 
          : <PasswordRecoveryForm/>
        }
      </div>
    </Wrapper>
  )
}

export default Login
