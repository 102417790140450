import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'bulma-toast';
import * as URI from 'utils/endpoints';
import { useI18n } from '../../contexts/I18nProvider';
import DataInput from './DataInput';
import DisplayResults from './DisplayResults';
import { useQuery } from 'react-query';

const Wrapper = styled.div`
  .column {
    .box {
      height: 100%;
    }
  }

  .downloads {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 16px;

    > div {
      _margin-right: 16px;
    }
  }
`;

const PageHeader = ({ title = 'title', subtitle = 'subtitle' }) => (
  <div className="has-text-centered mb-6">
    <h3 className="title is-4">{title}</h3>
    <p className="is-size-5">{subtitle}</p>
  </div>
);

const PlanningPage = () => {
  const [simulation, setSimulation] = React.useState();
  const { messages } = useI18n();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [simulation])

  const query = useQuery(
    'sim_input_abc',
    () => axios.get(URI.API_CAMPAIGN_SIMULATOR_INPUT),
    {
      refetchOnWindowFocus: false,
    }
  );



  const saveSimulation = async () => {
    // setSimulation(null);
    const result = await axios.post(
      `${URI.API_CAMPAIGN_SIMULATOR_SAVE}?sim_id=${simulation.simId}`
    );
    if (result.data.response.toLowerCase() === 'ok') {
      toast({
        message: 'Salvataggio completato.',
        type: 'is-success',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    } else {
      toast({
        message: 'Salvataggio fallito.',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    }
  };
  const sendSimulation = async () => {
    // setSimulation(null);
    const result = await axios.post(
      `${URI.API_CAMPAIGN_SIMULATOR_SEND}?sim_id=${simulation.simId}`
    );
    if (result.data.response.toLowerCase() === 'ok') {
      toast({
        message: 'Invio completato.',
        type: 'is-success',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    } else {
      toast({
        message: 'Invio fallito.',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    }
  };

  if(query.isError || query.isLoading){
    return  null
  }
  const { social, theme, contentType } = query?.data?.data?.data;

  return (
    <Wrapper>
      <PageHeader
        title={messages.cmp_sim_title}
        subtitle={messages.cmp_sim_description}
      />

      {simulation ? (
        <>
          <DisplayResults simulationResults={simulation} social={social} theme={theme} contentTypes={contentType}/>
          <div className="has-text-centered p-4">
            <button
              className="button simulate-btn has-text-white is-info mr-4"
              disabled={!simulation}
              onClick={e => {
                e.preventDefault();
                saveSimulation();
              }}
            >
              Salva
            </button>
            <button
              className="button simulate-btn has-text-white is-info"
              disabled={!simulation}
              onClick={e => {
                e.preventDefault();
                sendSimulation();
              }}
            >
              Invia
            </button>
          </div>
          <div className="has-text-centered p-4">
            <button
              className="button simulate-btn has-text-white is-success"
              disabled={!simulation}
              onClick={e => {
                e.preventDefault();
                setSimulation(null);
              }}
            >
              Nuova
            </button>
          </div>
        </>
      ) : (
        <DataInput handleSimulationResult={setSimulation} />
      )}
    </Wrapper>
  );
};

export default PlanningPage;
