import qs from 'qs';
import { get, omit } from 'lodash';

import { types as typesChart } from './data-charts';

export const types = {
  ON_CAMPAIGN_FILTERS_UPDATE: 'ON_CAMPAIGN_FILTERS_UPDATE',
};

// function formatQueryString(filters) {
//   return ''
// }

export const onCampaignFiltersUpdate = state => next => async action => {
  if (action.type === types.ON_CAMPAIGN_FILTERS_UPDATE) {
    const { endpointsByRole, updatedFilters } = action.payload;
    // console.log({payload: action.payload})
    const tutteId = get(state.getState(), 'campaign.tutteId', '0');
    // NOTE: trasformo group === '0' in tutteId, se necessario prima della request
    const groupId =
      action.payload?.updatedFilters.group === '0'
        ? '0' // tutteId
        : action.payload.updatedFilters.group;

    const params = qs.stringify(
      { ...action.payload.updatedFilters, group: groupId },
      { encode: false }
    );
    try {
      /** chart 0 */
      state.dispatch({
        type: typesChart.CAMPAIGN_CHART_0_REQUEST,
        payload: { params, endpointsByRole },
      });
    } catch (error) {
      console.log('onCampaignFiltersUpdate 0', { error });
    }
    try {
      /** admin table */
      const adminParams = qs.stringify(
        {
          ...omit(action.payload.updatedFilters, 'campaign'),
          group: groupId,
        },
        { encode: false }
      );

      state.dispatch({
        type: 'ADMIN_STATS_REQUEST',
        payload: {
          params: adminParams,
          timeframe: action.payload?.updatedFilters.timeframe,
        },
      });
    } catch (error) {
      console.log('onCampaignFiltersUpdate:::ADMIN_STATS_REQUEST', { error });
    }

    try {
      /** chart 2 */
      state.dispatch({
        type: typesChart.CAMPAIGN_CHART_2_REQUEST,
        payload: { params, endpointsByRole },
      });
    } catch (error) {
      console.log('onCampaignFiltersUpdate 2', { error });
    }
    try {
      /** chart 5 */
      state.dispatch({
        type: typesChart.CAMPAIGN_CHART_5_REQUEST,
        payload: { params, endpointsByRole },
      });
    } catch (error) {
      console.log('onCampaignFiltersUpdate 5', { error });
    }
  }
  next(action);
};
