import React /*, {useEffect , useState }*/ from 'react';
import axios from 'axios';
// import {useDispatch, useSelector} from 'react-redux'
// import {defineMessages} from 'react-intl'
import { useQuery } from 'react-query';
import { get } from 'lodash';
import { FaUser } from 'react-icons/fa';
import { AiFillPieChart } from 'react-icons/ai';
import { BiNetworkChart } from 'react-icons/bi';

import { useI18n } from 'contexts/I18nProvider';
import { useSession } from 'contexts/SessionProvider';
import { homeQuery } from 'services/api';
import Box from 'components/page/Box';
import Stats from 'components/page/Stats';
import BoxHeader from 'components/page/BoxHeader';
import WelcomeBox from 'components/page/WelcomeBox';
import Callouts from './components/Callouts';
import Options from './components/Options';
import Watcher from './components/Watcher';
import Welcome2 from './components/Welcome2';
import { createEndpointsByRoleFORHOME } from 'utils/endpoints';
// import {types} from 'store/middlewares/lifecycle'

const HomePage = (/* {history, location, match} */) => {
  const { messages } = useI18n();
  const { user } = useSession();

  const url = createEndpointsByRoleFORHOME(user);

  const queryInfo = useQuery(
    'home',
    async () => {
      const result = await axios.get(url);
      return result;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const data = get(queryInfo, 'data.data.data', {});

  return (
    <div>
      <Box classnames="has-text-white has-blue-bg">
        <WelcomeBox />
      </Box>
      <Box classnames="is-shadowless">
        <BoxHeader icon={<FaUser />} title={messages.summaryTitleString} />
        <Stats
          data={data}
          loading={queryInfo.isLoading}
          error={queryInfo.isError}
          itemClassName="home"
        />
      </Box>
      <Box>
        <BoxHeader
          icon={<AiFillPieChart />}
          title={messages.dashboardTitleString}
        />
        <Callouts />
      </Box>
      <Box>
        <BoxHeader
          icon={<BiNetworkChart />}
          title={messages.rankitTitleString}
          subtitle={messages.rankitDescriptionString}
        />
        <Options />
      </Box>
      <Box>
        <Welcome2 />
      </Box>
    </div>
  );
};

export default HomePage;
