import React from 'react';
import { useForm } from 'react-hook-form';
import { useI18n } from 'contexts/I18nProvider';
import { useSession } from 'contexts/SessionProvider';
import FieldErrorMessage from 'components/page/FieldErrorMessage';

const SigninForm = () => {
  const { register, handleSubmit, errors } = useForm();
  const { signIn, responseError, isLoading } = useSession();
  const { messages } = useI18n();

  const onSubmit = data => {
    signIn(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {responseError ? <FieldErrorMessage msg="Utente non valido" /> : null}
      <div className="field">
        <label className="label">
          {messages.usernameString}{' '}
          {errors.email && <FieldErrorMessage msg="email non valido" />}
        </label>
        <div className="control">
          <input
            name="email"
            ref={register({ required: true })}
            className="input"
            type="text"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">
          {messages.passwordString}{' '}
          {errors.password && <FieldErrorMessage msg="password non valida" />}
        </label>
        <div className="control">
          <input
            name="password"
            ref={register({ required: true })}
            className="input"
            type="password"
          />
        </div>
      </div>
      <div className="field">
        <p className="control">
          <button type="submit" className="button is-info" disabled={isLoading}>
            {isLoading ? messages.loadingString : messages.loginButtonString}
          </button>
        </p>
      </div>
      {/* <div>
      <Link to="/password-recovery">Recupera la tua password.</Link>        
      </div> */}
    </form>
  );
};

export default SigninForm;
