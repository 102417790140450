import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import * as api from 'services/api';
import { RANKIT_APP_TOKEN, TOKEN_POLL_INTERVAL } from 'utils/config';

export const AuthContext = createContext({
  isAppAuthorized: false,
});

const Warning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
  background-color: ${props => props.theme.colors.blue};

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 96px;
      height: auto;
    }
  }
`;

const AuthProvider = ({ children }) => {
  const [isAppAuthorized, setIsAppAuthorized] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  // console.log('AuthProvider');

  useEffect(() => {
    async function exec() {
      const { token, error } = await api.token();

      if (error) {
        // console.log({error})
        setErrMessage(error);
      }
      window.localStorage.setItem(RANKIT_APP_TOKEN, token);
      setIsAppAuthorized(!!token);
    }
    exec();
    // start polling ...
    const timer = setInterval(exec, TOKEN_POLL_INTERVAL);
    // cleanup function
    return function () {
      window.localStorage.removeItem(RANKIT_APP_TOKEN);
      clearInterval(timer);
    };
  }, []);

  return (
    <AuthContext.Provider value={isAppAuthorized}>
      {isAppAuthorized ? (
        children
      ) : (
        <Warning>
          <div>
            <img src="/images/Rankit_Logo_Bianco.png" alt="Rankit" />
            Application setup ... {errMessage}
          </div>
        </Warning>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export function useAuth() {
  const isAppAuthorized = useContext(AuthContext);
  return isAppAuthorized;
}
