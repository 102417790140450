import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducers'
import rootMiddleware from './middlewares'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    /* ...getDefaultMiddleware({serializableCheck: false}),  */
    ...rootMiddleware
  ]
})

// export type RootState = ReturnType<typeof rootReducer>
export type RootState = ReturnType<typeof store.getState>

export default store