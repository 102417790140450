import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useSession } from 'contexts/SessionProvider';
import { useI18n } from 'contexts/I18nProvider';

const Article = styled.article`
  align-items: center;

  .username {
    color: ${props => props.theme.colors.yellow};
  }
`;

// const homeText = `Benvenuto nella tua dashboard. Potrai monitorare nel dettaglio le tue campagne e utilizzare i tool di Rankit per analizzare l’andamento dei Social Media, intercettare le migliori community e valutare l’engagement rate insieme agli hashtag nei trend topic.`

// const campaignText = `In questa pagina puoi monitorare le performance delle tue campagne confrontandole tra loro e ricavare informazioni utili per migliorarle.`

const WelcomeBox = ({ text }) => {
  const location = useLocation();
  const { user } = useSession();
  const { messages } = useI18n();

  const isHome = location.pathname === '/';
  const claim = isHome ? messages.welcomeDescriptionString : '';

  return (
    <div>
      <Article className="media">
        <div className="media-content">
          <div className="content">
            <div className="mb-2">
              {`${messages.welcomeTitleString} `}
              <span className="username is-size-5 has-text-weight-bold">
                {user ? user.username : ''}
              </span>
              ,
            </div>
            <div>{claim}</div>
          </div>
        </div>
        {isHome && (
          <figure className="media-right">
            <div className="image is-96x96">
              <img src="/images/Frankit-resized.png" alt="Frankit" />
            </div>
          </figure>
        )}
      </Article>
    </div>
  );
};

export default WelcomeBox;
