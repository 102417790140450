import {combineReducers} from 'redux'

// import testReducer from './test.reducer'
import summaryReducer from './summary.reducer'
import campaignReducer from './campaign.reducer'
import chartsReducer from './charts.reducer'

export default combineReducers({
  // test: testReducer,
  summary: summaryReducer,
  campaign: campaignReducer,
  charts: chartsReducer,
})