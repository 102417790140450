import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import ProgressBar from 'components/ProgressBar';
import { useI18n } from 'contexts/I18nProvider';

const Wrapper = styled.div``;

const ItemWrapper = styled.div`
  padding: 16px;
  border-radius: ${props => props.theme.border.radius};

  &.home {
    color: #fff;
    background-color: ${props => props.theme.colors.yellow};
  }

  &.campaign {
    color: #fff;
    background-color: ${props => props.theme.colors.pink};
  }

  &.analyze {
    height: 100%;
    color: #fff;
    background-color: ${props => props.theme.colors.orange};
  }

  .stat-title {
    line-height: 1;
    margin-bottom: 8px;
  }
`;
/**
 *
 * value was {name === 'expense' ? numeral(value).format('0,0[.]00 $') : numeral(value).format('0,0[.]0')}
 */
const Item = ({ name, value, classname = '' }) => (
  <div className="column is-half">
    <ItemWrapper className={classname}>
      <span className=" stat-title is-size-3">{value}</span> <span>{name}</span>
    </ItemWrapper>
  </div>
);

const localize = ({ messages, currencySymbol }) => ({ key, item }) => {
  const map = {
    'in corso': messages.summaryOngoingString,
    ongoing: messages.summaryOngoingString,
    completate: messages.summaryCompletedString,
    completed: messages.summaryCompletedString,
    utenti: messages.summaryUsersString,
    users: messages.summaryUsersString,
    drivers: messages.summaryDriversString,
    profileAverageEr: messages.instagramAnalyzeProfileMetricAverageEr,
    profileFollower: messages.instagramAnalyzeProfileMetricFollower,
    profileFollowing: messages.instagramAnalyzeProfileMetricFollowing,
    profilePostNumber: messages.instagramAnalyzeProfileMetricPostNumber,
  };
  return { name: get(map, `${key}`, key), value: item };
};

const StatsAnalyze = ({ data, itemClassName, loading, error = false }) => {
  const { messages, currencySymbol } = useI18n();
  const mapper = localize({ messages, currencySymbol });
  const keys = Object.keys(data);

  return error ? (
    <div>Si è verificato un errore.</div>
  ) : (
    <Wrapper className="columns is-multiline">
      {loading ? (
        <ProgressBar />
      ) : (
        keys.map((key, index) => {
          const { name, value } = mapper({ key, item: data[key] });
          return (
            <Item
              key={name}
              name={name}
              value={value}
              classname={`${index % 2 !== 0 ? 'campaign' : 'analyze'}`}
            />
          );
        })
      )}
    </Wrapper>
  );
};

export default StatsAnalyze;
