import React from 'react'
import {Line} from 'react-chartjs-2'

const View = ({data = {}, options = {}, redraw = true, height = 300}) => {
  return (
    <div style={{position: 'relative'}}>
      <Line data={data} options={options} redraw={redraw} height={height} />
    </div>
  )
}

export default View
