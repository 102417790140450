import React from 'react';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'bulma-toast';
import * as URI from 'utils/endpoints';
import Toggle from 'react-toggle';
import { useI18n } from '../../contexts/I18nProvider';

const SubTitle = ({ alt, text = '' }) => (
  <h5
    className={`title is-5 p-3 has-text-white has-blue-bg ${
      alt ? 'alt-title' : ''
    }`}
  >
    {text}
  </h5>
);

const ToggleButton = ({ selected, children, onClick }) => {
  const cname = `button ${selected ? 'is-info' : ''}`;
  return (
    <button
      className={cname}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </button>
  );
};

const initialValues = {
  kpi: '',
  duration: '1w',
  contentType: 10000000,
  social: [],
  theme: [],
  isBudget: true,
  budget: 0,
  target: 0,
};

function isValid(formData) {
  const { social, theme, isBudget, budget, target } = formData;
  return (isBudget && budget) || (!isBudget && target);
}

const DataInput = ({ handleSimulationResult }) => {
  const { messages } = useI18n();
  const [formData, setFormData] = React.useState(initialValues);

  const query = useQuery(
    'sim_input',
    () => axios.get(URI.API_CAMPAIGN_SIMULATOR_INPUT_2),
    {
      refetchOnWindowFocus: false,
      //staleTime: Infinity,
    }
  );

  if (query.isError) {
    return <div>error</div>;
  }
  if (query.isLoading) {
    return <div>loading</div>;
  }

  const submitHandler = async event => {
    event.preventDefault();
    // reset calculated simulation
    handleSimulationResult(null);
    if (!isValid(formData)) {
      toast({
        message: 'I dati inseriti non sono validi.',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
      return;
    }
    // mutation.mutate(formData);
    const updateFormData = {
      ...formData,
      isBudget: formData.isBudget ? 1 : 0,
      target: Number(formData.target),
      budget: Number(formData.budget),
      theme: formData.theme[0] || '',
      social: formData.social[0] || '',
    };
    // console.log('#@#@', { formData, updateFormData });
    const result = await axios.post(
      URI.API_CAMPAIGN_SIMULATOR_CALC,
      updateFormData
    );
    console.log({ result });
    if (
      result.data.response.toLowerCase() === 'ok' &&
      !!result.data.data.simId
    ) {
      handleSimulationResult(result.data.data);
      setFormData(initialValues);
    } else {
      toast({
        message: 'Per favore compila tutti i campi.', //result.data.data,
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    }
    console.log({ result });
  };

  const { duration, kpi } = query.data.data.data;

  const temp = kpi.find(item => item.value == formData.kpi) || {};

  console.log({ temp });

  const currentTheme = temp.theme || [];
  const currentSocial = temp.social || [];
  const currentContentType = temp.contentType || [];

  return (
    <form onSubmit={submitHandler} className="DataInputForm">
      <div className="columns mb-3" style={{ padding: '0.75rem' }}>
        <div className="column box simulation-section mb-0 mr-4">
          <SubTitle text={`1. ${messages.cmp_sim_filter_kpi}`} />
          <div className="_columns _has-text-centered is-flex is-flex-direction-column special-radio-reset px-5">
            {kpi.map(item => (
              <label className="radio display-block" key={item.label}>
                <input
                  type="radio"
                  name="kpi"
                  checked={formData.kpi == item.value}
                  onChange={e => setFormData({ ...formData, kpi: item.value })}
                />
                <span className="ml-4">{item.label}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="column box simulation-section ml-4">
          <SubTitle text={`2. ${messages.cmp_sim_filter_target}`} />
          <div className="_is-flex _is-justify-content-space-evenly px-5">
            <div className="columns">
              <div className="column">
                <label className="radio display-block" key="bdg0">
                  <input
                    type="radio"
                    name="isBudget"
                    checked={formData.isBudget}
                    onChange={e =>
                      setFormData({ ...formData, isBudget: !formData.isBudget })
                    }
                  />
                  <span className="ml-4">Imposta il budget</span>
                </label>
              </div>
              <div className="column">
                <input
                  name="budget"
                  className={`input ${formData.isBudget ? 'is-outlined' : ''}`}
                  type="number"
                  onChange={e =>
                    setFormData({ ...formData, budget: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <label className="radio display-block" key="bdg0">
                  <input
                    type="radio"
                    name="isBudget"
                    checked={!formData.isBudget}
                    onChange={e =>
                      setFormData({ ...formData, isBudget: !formData.isBudget })
                    }
                  />
                  <span className="ml-4">Imposta l'obiettivo</span>
                </label>
              </div>
              <div className="column">
                <input
                  name="target"
                  className={`input ${formData.isBudget ? '' : 'is-outlined'}`}
                  type="number"
                  onChange={e =>
                    setFormData({ ...formData, target: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box simulation-section p-4 mb-3">
        <SubTitle text={`3. ${messages.cmp_sim_filter_content}`} />
        <div className="control columns has-text-centered">
          {currentContentType.map(item => {
            return (
              <div className="column" key={item.label}>
                <label className="radio">
                  <input
                    type="radio"
                    name="contentType"
                    checked={formData.contentType == item.id}
                    onChange={e => {
                      console.log('contentType -> item value', { item });
                      setFormData({ ...formData, contentType: item.id });
                    }}
                  />
                  <span className="ml-4">{item.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="box simulation-section p-4 mb-3">
        <SubTitle text={`4. ${messages.cmp_sim_filter_campaign_settings}`} />
        <div className="columns">
          <div className="column">
            <SubTitle alt text={`${messages.cmp_sim_filter_campaign_social}`} />
            <ul className="sized-ul px-5">
              {currentSocial.map(item => {
                return (
                  <li key={item.label}>
                    <label className="radio">
                      <input
                        type="radio"
                        name="social"
                        checked={formData.social[0] == item.id}
                        onChange={e =>
                          setFormData({ ...formData, social: [item.id] })
                        }
                      />
                      <span className="ml-4">{item.label}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column">
            <SubTitle alt text={`${messages.cmp_sim_filter_campaign_theme}`} />
            <ul className="sized-ul px-5">
              {currentTheme.map(item => {
                return (
                  <li key={item.label}>
                    <label className="radio">
                      <input
                        type="radio"
                        name="theme"
                        checked={formData.theme[0] == item.id}
                        onChange={e =>
                          setFormData({ ...formData, theme: [item.id] })
                        }
                      />
                      <span className="ml-4">{item.label}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="has-text-centered p-4">
        <button
          type="submit"
          className="button simulate-btn has-text-white is-success"
        >
          {messages.cmp_sim_button_calc}
        </button>
      </div>
    </form>
  );
};

export default DataInput;
