import React from 'react';
import { useSelector } from 'react-redux';
import Color from 'color';
import { get, isEmpty } from 'lodash';
import { format } from 'date-fns';
import { isLabelFormattable } from 'utils/dates';

import {
  useCampaignFiltersContext,
  CampaignFiltersContext,
} from 'contexts/CampaignFiltersProvider';
import ProgressBar from 'components/ProgressBar';
import NoDataMessage from 'components/page/NoDataMessage';
import View from './Chart6.view';
import { rgbs } from 'utils/chartConfig';
import { useI18n } from 'contexts/I18nProvider';

const height = 460; //  + (labels.length / 4) * 35
const options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'top',
    align: 'start',
    labels: {
      boxWidth: 12,
      fontSize: 12,
    },
  },
  layout: {
    padding: 0,
  },
  elements: {
    line: {
      borderWidth: 1,
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};

const Chart6 = () => {
  const { dateFormat } = useI18n();
  const { filters } = useCampaignFiltersContext(CampaignFiltersContext);
  const rawData = useSelector(state => state.charts.chart0);
  const loading = useSelector(state => state.charts.chart0Status);

  const isFormattable = isLabelFormattable(filters.timeframe);

  let data = get(rawData, 'data');
  let chartData = {};

  if (!loading) {
    if (isEmpty(data)) return <NoDataMessage />;

    if (data && !isEmpty(data)) {
      const keys = Object.keys(data);
      const firstItemData = data[keys[0]].data2;

      const labels = Object.keys(firstItemData).map(key =>
        isFormattable ? key : format(new Date(key), dateFormat)
      );
      const colors = rgbs(labels.length);

      const datasets = keys.map((key, i) => {
        const obj = data[key];
        const color = Color(colors[i]);
        return {
          label: obj.title,
          data: Object.values(obj.data2),
          fill: true,
          borderColor: color.string(),
          backgroundColor: color.alpha(0.5).string(), //'rgba(0,0,0,0)', //
        };
      });
      chartData = { labels, datasets };
    }
  }

  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : (
        <View
          data={chartData}
          options={options}
          height={height}
          redraw={true}
        />
      )}
    </div>
  );
};

export default Chart6;
