import React from 'react';
import { Bar } from 'react-chartjs-2';

const View = ({ data = {}, options = {}, redraw = true, height = 300 }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Bar data={data} options={options} redraw={redraw} height={height} />
    </div>
  );
};

export default View;
