import axios from 'axios';

import { API_BASE_URL } from 'utils/endpoints';
import { RANKIT_APP_TOKEN, RANKIT_APP_USER } from 'utils/config';

/**
 * axios configuration
 */
// function initAxios() {
//   const auth = `client:wqeqwq43423`; // `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}` //
//   axios.defaults.baseURL = API_BASE_URL;
//   axios.defaults.headers.common['Authorization'] = `Basic ${window.btoa(auth)}`;
// }

export function initAxiosWithToken(token) {
  axios.defaults.baseURL = API_BASE_URL;
  // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

/**
 * axios interceptors
 */
function addInterceptors() {
  axios.interceptors.request.use(
    config => {
      // doing login
      const isSignin = config.url.indexOf('signin') > -1;
      // admin stats url
      const isStats = config.url.indexOf('stats') > -1;
      // const isAuthUrl = config.url.endsWith('login');
      // const isExport = config.url.endsWith('export.php')
      // console.log('isSignin', isSignin, config.url);

      // login endpoint
      if (isSignin) return config;

      const token = window.localStorage.getItem(RANKIT_APP_TOKEN);

      let updatedConfig = {
        ...config,
        headers: {
          common: {
            ...config.headers.common,
            Authorization: `Bearer ${token}`,
          },
        },
      };

      if (isStats) return updatedConfig;

      // export endpoint
      // if(isExport)
      // other endpoints
      // ... add token in qs
      // const token = window.localStorage.getItem(RANKIT_APP_TOKEN);
      // ... add advertiser_id
      const user = JSON.parse(window.localStorage.getItem(RANKIT_APP_USER));
      const advId = user ? user['advertiser_id'] : '';
      const userId = user ? user['user_id'] : '';
      const userGroupId = user ? user['user_group_id'] : '';

      updatedConfig = {
        ...updatedConfig,
        params: {
          ...updatedConfig.params,
          advertiser_id: advId,
          user_id: userId,
          user_group_id: userGroupId,
        },
      };
      return updatedConfig;
    },
    error => {
      // console.log({error})
      return Promise.reject(error);
    }
  );
}

// initAxios();
addInterceptors();
