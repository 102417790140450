import React, { createContext, useContext, useState } from 'react';

export const NavContext = createContext({
  open: false,
  toggleNav: () => {},
});

const NavProvider = ({ children }) => {
  const [open, setOpen] = useState(true);

  const toggleNav = () => setOpen(!open);

  return (
    <NavContext.Provider value={{ open, toggleNav }}>
      {children}
    </NavContext.Provider>
  );
};

export default NavProvider;

export function useNav() {
  const ctx = useContext(NavContext);
  return ctx;
}
