export default {
  border: {
    radius: '8px',
  },
  colors: {
    black: 'rgb(31, 31, 31)',
    blue: 'rgb(46, 165, 220)',
    pink: 'rgb(233, 24, 99)',
    greyBlue: 'rgb(64, 80, 104)',
    green: 'rgb(53, 180, 73)',
    yellow: 'rgb(251, 222, 85)',
    white: 'rgb(255, 255, 255)',
    orange: 'rgb(255, 150, 0)',
  },
  gap: '8px',
};
