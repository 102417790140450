// import {Chart} from 'react-chartjs-2'
// import 'chartjs-plugin-zoom'
// import 'chartjs-plugin-crosshair'

/** see https://www.rapidtables.com/web/color/html-color-codes.html */
export const chartColors = {
  a: '#02B6F7',
  b: '#6cd991',
  c: '#fedc57',
  d: '#cd70a9',
  e: '#6cc3d9',
  f: '#6D7BD9',
  g: '#D96D7B',
  h: '#D9836D',
  i: '#D96DC4',
}

export function rgbs(maxCount) {

  // console.log({maxCount})
  const values = Object.values(chartColors)
  return [...values, ...values, ...values, ...values, ...values, ...values, ...values, ...values, ...values]
  // let counter = 0
  // const list = []
  // console.log('->', list.length)
  // for(let i = 0; i < maxCount; i++){
  //   list.push(values[counter])
  //   counter = counter + 1 === list.length ? 0 : counter++
  //   console.log({counter})
  // }
  // return list
  // const iter = values[Symbol.iterator]()
  // return {
  //   next: iter.next()
  // }
}

// const {point} = Chart.defaults.global.elements

// point.pointStyle = 'cross'
// point.borderColor = 'rgba(0,0,0,0)'

// export const crosshair = {
//   line: {
//     color: '#F66',  // crosshair line color
//     width: 1        // crosshair line width
//   },
//   sync: {
//     enabled: true,            // enable trace line syncing with other charts
//     group: 1,                 // chart group
//     suppressTooltips: false   // suppress tooltips when showing a synced tracer
//   },
//   zoom: {
//     enabled: true,                                      // enable zooming
//     zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
//     zoomboxBorderColor: '#48F',                         // border color of zoom box
//     zoomButtonText: 'Reset Zoom',                       // reset zoom button text
//     zoomButtonClass: 'reset-zoom',                      // reset zoom button class
//   },
//   callbacks: {
//     beforeZoom: function(start, end) {                  // called before zoom, return false to prevent zoom
//       return true;
//     },
//     afterZoom: function(start, end) {                   // called after zoom
//     }
//   }
// }

/** https://github.com/chartjs/chartjs-plugin-zoom */
// export const zoom = {
//   pan: {
//     enabled: false,
//     mode: 'x',
//     rangeMin: {
//       x: null,
//       y: null
//     },
//     rangeMax: {
//       x: null,
//       y: null
//     },
//     speed: 20,
//     threshold: 10,
//     onPan: ({chart}) => {console.log(`I'm panning!!!`);},
//     onPanComplete: ({chart}) => { console.log(`I was panned!!!`); }
//   },
//   zoom: {
//     enabled: true,
//     drag: true,
//     // drag: {
//     // 	 borderColor: 'rgba(225,225,225,0.3)',
//     // 	 borderWidth: 5,
//     // 	 backgroundColor: 'rgb(225,225,225)',
//     // 	 animationDuration: 0,
//     // },
//     mode: 'x',
//     rangeMin: {
//       x: null,
//       y: null
//     },
//     rangeMax: {
//       x: null,
//       y: null
//     },
//     speed: 0.1,
//     threshold: 2,
//     sensitivity: 3,
//     onZoom: ({chart}) => { console.log(`I'm zooming!!!`); },
//     onZoomComplete: ({chart}) => { console.log(`I was zoomed!!!`); }
//   }
// }

// Chart.plugins.register({
//   // plugin implementation
//   zoom
// });
