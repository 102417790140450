import React, { createContext, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { types } from 'store/reducers/types';
import * as api from 'services/api';
import * as endpoints from 'utils/endpoints';
import {
  RANKIT_APP_TOKEN,
  RANKIT_APP_USER,
  RANKIT_APP_USER_LANG,
  RANKIT_APP_USER_LANG_VERSION,
} from 'utils/config';
import useLocalStorageState from 'hooks/useLocalStorageState';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { initAxiosWithToken } from 'utils/axiosConfig';

/** get user from browser localStorage */
// const user = localStorage.getItem(RANKIT_APP_USER)
// const storedUser = (user && user !== 'undefined') ? JSON.parse(user) : null

export const SessionContext = createContext({
  user: null,
  signIn: () => {},
  signOut: () => {},
  resetPassword: () => {},
});

// function updateLocalStorage(data) {
//   if(data)
//     localStorage.setItem(RANKIT_APP_USER, JSON.stringify(data))
//   else
//     localStorage.removeItem(RANKIT_APP_USER)
// }

/**
 * Provides:
 * - User data, loading info
 * - methods to login/out
 * - a function to retrive endpoints based on current user roles
 */
const SessionProvider = ({ children }) => {
  // const [user, setUser] = useState(storedUser)
  // NOTE: try hooks.useLocalStorageState()
  const [cookies] = useCookies();
  const [user, setUser] = useLocalStorageState(RANKIT_APP_USER);
  const [userLang, setUserLang] = useLocalStorageState(RANKIT_APP_USER_LANG);
  const [userLangVersion, setUserLangVersion] = useLocalStorageState(
    RANKIT_APP_USER_LANG_VERSION
  );
  const [endpointsByRole, setEndpointsByRole] = useState(endpoints);
  const [responseError, setResponseError] = useState();
  const dispatch = useDispatch();
  const [mutate, { error, isLoading, isError }] = useMutation(api.signin);
  const history = useHistory();

  const cookieQueryInfo = useQuery(
    'cookie-query',
    () => api.signInWithCookie(cookies.dh),
    {
      enabled: !!cookies.dh,
      onSuccess: data => {
        console.log({ data });
        setUserLang(data.lang);
        setUserLangVersion(data.lang_version);
        setUser(data);
        // update endpoints based on user role
        const eps = endpoints.createEndpointsByRole(endpoints, data);
        if (!!eps) setEndpointsByRole(eps);
      },
      onError: error => setResponseError(error),
    }
  );

  useEffect(() => {
    window.addEventListener('beforeunload', signOut);
    return () => window.removeEventListener('beforeunload', signOut);
  }, []);

  /** user signin mutation */
  const signIn = async formData => {
    const mutationInfo = await mutate(formData);
    const { data, error } = mutationInfo;
    // console.log('@', data);
    // updateLocalStorage(data)
    if (data) {
      setUserLang(data.lang);
      setUserLangVersion(data.lang_version);
      setUser(data);

      // const { token } = data;

      // set token in localstorage
      window.localStorage.setItem(RANKIT_APP_TOKEN, data.token);

      // console.log('token ->', token);
      // init axios interceptor
      // initAxiosWithToken(token);

      // update endpoints based on user role
      const eps = endpoints.createEndpointsByRole(endpoints, data);
      if (!!eps) setEndpointsByRole(eps);
    }
    if (error) setResponseError(error);
  };

  /** user signout */
  const signOut = () => {
    // updateLocalStorage()
    setUser(null);
    setResponseError(null);
    dispatch({ type: types.ON_LOGOUT });
    history.push('/login');
  };

  // TODO: gestire la risposta
  /** reset user password */
  const resetPassword = async formData => {
    await api.resetPassword(formData);
  };

  const getEndpointsByRole = () => endpointsByRole;

  const contextValue = {
    error,
    isLoading,
    isError,
    responseError,
    user,
    // userLang,
    // userLangVersion,
    getEndpointsByRole,
    resetPassword,
    signIn,
    signOut,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

export const useSession = () => {
  const session = useContext(SessionContext);
  if (!session) throw new Error('session contextdoes not exists');
  return session;
};
