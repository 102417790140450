import React from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import styled from 'styled-components';

import { ThemeProvider } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import theme from 'styles/theme';
import GlobalStyle from 'styles/globalstyle';
import SessionProvider from 'contexts/SessionProvider';
import AuthProvider from 'contexts/AuthProvider';
import I18nProvider from 'contexts/I18nProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import PrivateRoute from 'components/PrivateRoute';
import AppLayout from 'components/layouts/AppLayout';
import HomePage from 'pages/home';
import CampaignPage from 'pages/campaign';
import PlanningPage from 'pages/planning';
import LoginPage from 'pages/login';
import AnalyzePage from 'pages/analyze';
import 'rsuite/dist/styles/rsuite-default.css';
// import 'bulma/css/bulma.min.css'
import 'animate.css/animate.min.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
// import 'bulma-extensions/bulma-switch/dist/css/bulma-switch.min.css';
import 'react-toggle/style.css';
import { CookiesProvider } from 'react-cookie';

const queryCache = new QueryCache();

const Wrapper = styled.div`
  .page-transition-enter {
    opacity: 0.01;
  }
  .page-transition-enter.page-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .page-transition-exit {
    opacity: 1;
  }
  .page-transition-exit.page-transition-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in();
  }
`;

function NotFoundScreen() {
  return <div>404: Page Not Found</div>;
}

const Routes = () => {
  const location = useLocation();
  const { pathname, key } = location;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Wrapper>
      <TransitionGroup>
        <CSSTransition
          key={key}
          timeout={{ enter: 500, exit: 500 }}
          in={true}
          classNames="page-transition"
        >
          <ErrorBoundary>
            <Switch location={location}>
              <Route
                path={['/login', '/password-recovery']}
                render={props => <LoginPage {...props} />}
              />
              <AppLayout>
                <Route
                  path="/campaign_simulator"
                  render={props => <PlanningPage {...props} />}
                />
                <PrivateRoute exact path="/" component={HomePage} />
                <PrivateRoute
                  path="/overview-performance"
                  component={CampaignPage}
                />
                <PrivateRoute path="/analyze/:id" component={AnalyzePage} />
              </AppLayout>
              <Route path="*" component={NotFoundScreen} />
            </Switch>
          </ErrorBoundary>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <CookiesProvider>
          {/* <AuthProvider> */}
          <BrowserRouter>
            <SessionProvider>
              <I18nProvider>
                <Routes />
              </I18nProvider>
              <GlobalStyle {...theme} />
            </SessionProvider>
          </BrowserRouter>
          {/* </AuthProvider> */}
        </CookiesProvider>
      </ReactQueryCacheProvider>
    </ThemeProvider>
  );
}

export default App;
