import React, { useState } from 'react';
import { Table } from 'rsuite';
import { sortBy, reverse } from 'lodash';

import NoDataMessage from 'components/page/NoDataMessage';
import { useI18n } from 'contexts/I18nProvider';

const { Cell, Column, HeaderCell, Pagination } = Table;

const View = ({
  data,
  loading,
  hasLead,
  hasReach,
  hasImpression,
  hasClick,
}) => {
  const [_sortType, toggleType] = useState('desc');
  const [_sortColumn, setCol] = useState('unique_users');
  const { messages, pagination } = useI18n();
  const [page, setPage] = useState(1);
  const [displayLength, setDisplayLength] = useState(pagination);

  let sortedDataTable = sortBy(data, [_sortColumn]);
  if (_sortType === 'desc') sortedDataTable = reverse(sortedDataTable);

  const paginatedData = sortedDataTable.filter((v, i) => {
    const start = displayLength * (page - 1);
    const end = start + displayLength;
    return i >= start && i < end;
  });

  const handleChangePage = eventKey => {
    setPage(eventKey);
  };

  // HANDLE SHOW OFFLINE CLICK ONLY FOR SUPERADMIN - USING USER GROUP
  let which = JSON.parse(localStorage.getItem('rankit-app-user'));

  return (
    <div key={_sortType}>
      <Table
        data={paginatedData}
        autoHeight
        loading={loading}
        renderEmpty={() => <NoDataMessage />}
        onSortColumn={(sortColumn, sortType) => {
          toggleType(_sortType === 'asc' ? 'desc' : 'asc');
          setCol(sortColumn);
        }}
        sortType={_sortType}
        sortColumn={_sortColumn}
      >
        <Column flexGrow={2} sortable>
          <HeaderCell>{messages.tableBrandString}</HeaderCell>
          <Cell dataKey="brand" />
        </Column>
        <Column flexGrow={2} sortable>
          <HeaderCell>{messages.tableCampaignString}</HeaderCell>
          <Cell dataKey="title" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableStartDateString}</HeaderCell>
          <Cell dataKey="dataInizio" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>{messages.tableEndDateString}</HeaderCell>
          <Cell dataKey="dataFine" />
        </Column>
        {hasLead ? (
          <Column flexGrow={1} sortable>
            <HeaderCell>{messages.tableTotalLeadString}</HeaderCell>
            <Cell dataKey="total_lead" />
          </Column>
        ) : null}
        {hasClick ? (
          <Column flexGrow={1} sortable>
            <HeaderCell>{messages.tableTotalClickString}</HeaderCell>
            <Cell dataKey="total_click" />
          </Column>
        ) : null}
        {which.user_group_id == 1 && !hasLead ? (
          <Column flexGrow={1} sortable>
            <HeaderCell>{messages.tableTotalClickOfflineString}</HeaderCell>
            <Cell dataKey="total_click_offline" />
          </Column>
        ) : null}
        {hasClick || hasLead ? (
          <Column flexGrow={1} sortable>
            <HeaderCell>{messages.tableTotalUserString}</HeaderCell>
            <Cell dataKey="unique_users" />
          </Column>
        ) : null}
        {hasReach ? (
          <Column flexGrow={2} sortable>
            <HeaderCell>
              {messages.tableReachString || 'Total Reach'}
            </HeaderCell>
            <Cell dataKey="total_reach" />
          </Column>
        ) : null}
        {hasImpression ? (
          <Column flexGrow={2} sortable>
            <HeaderCell>
              {messages.tableTotalImpressionString || 'Total Impressions'}
            </HeaderCell>
            <Cell dataKey="total_impression" />
          </Column>
        ) : null}
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          size="xs"
          lengthMenu={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
          ]}
          total={sortedDataTable.length}
          displayLength={displayLength}
          activePage={page}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  );
};

export default View;
