import {
  addDays,
  subDays,
  differenceInDays,
  isYesterday as isFnsYesterday,
  isToday as isFnsToday,
  lastDayOfYear,
  startOfYear,
  isSameDay,
  subYears,
  subMonths,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';

const applyFunction = fn => () => {
  return fn(subMonths(new Date(), 1));
};
const lastDayOfLastMonth = applyFunction(lastDayOfMonth);
const firstDayOfLastMonth = applyFunction(startOfMonth);

function isLabelFormattable(value) {
  const timeframeValues = [
    'today',
    'yesterday',
    'last7',
    'last30',
    'lastMonth',
    'currentMonth',
    'lastYear',
    'currentYear',
  ];
  return timeframeValues.includes(value); // || value.indexOf('-') !== -1;
}

function isLast7(timeframe) {
  const [start, end] = timeframe;
  return differenceInDays(start, end) === -6;
}

function isLast30(timeframe) {
  const [start, end] = timeframe;
  return (
    differenceInDays(start, end) === -30 &&
    end.getDate() === new Date().getDate()
  );
}

function isYesterday(timeframe) {
  const [start, end] = timeframe;
  return isFnsYesterday(start) && isFnsYesterday(end);
}

function isToday(timeframe) {
  const [start, end] = timeframe;
  return isFnsToday(start) && isFnsToday(end);
}

function isLastMonth(timeframe) {
  const [start, end] = timeframe;
  return (
    isSameDay(start, firstDayOfLastMonth()) &&
    isSameDay(end, lastDayOfLastMonth())
  );
}

function isCurrentMonth(timeframe) {
  const [start, end] = timeframe;
  return (
    isSameDay(start, startOfMonth(new Date())) && isSameDay(end, new Date())
  );
}

function isCurrentYear(timeframe) {
  const [start] = timeframe;
  return isSameDay(start, startOfYear(new Date()));
}

function isStartOfYear(timeframe) {
  const [start] = timeframe;
  return isSameDay(startOfYear(start), startOfYear(new Date()));
}

function isLastYear(timeframe) {
  const [start] = timeframe;
  return isSameDay(start, subYears(startOfYear(new Date()), 1));
}

function dateRanges(messages) {
  return [
    {
      label: messages.selectCampaignTimeframeYesterdayString,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: messages.selectCampaignTimeframeTodayString,
      value: [new Date(), new Date()],
    },
    {
      label: messages.selectCampaignTimeframeLast7String,
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: messages.selectCampaignTimeframeLast30String,
      value: [subDays(new Date(), 30), new Date()],
    },
    {
      label: messages.selectCampaignTimeframeCurrentMonthString,
      value: [startOfMonth(new Date()), new Date()],
    },
    {
      label: messages.selectCampaignTimeframeLastMonthString,
      value: [firstDayOfLastMonth(), lastDayOfLastMonth()],
    },
    {
      label: messages.selectCampaignTimeframeCurrentYearString,
      value: [startOfYear(new Date()), new Date()],
    },
    {
      label: messages.selectCampaignTimeframeLastYearString,
      value: [
        subYears(startOfYear(new Date()), 1),
        subYears(lastDayOfYear(new Date()), 1),
      ],
    },
  ];
}

export {
  isLast7,
  isLast30,
  isYesterday,
  isToday,
  isCurrentMonth,
  isLabelFormattable,
  isLastMonth,
  isCurrentYear,
  isLastYear,
  dateRanges,
  isStartOfYear
};
