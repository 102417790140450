import React from 'react';
import styled from 'styled-components';

import Stats from 'components/page/Stats';
import { useI18n } from 'contexts/I18nProvider';
import Box from 'components/page/Box';
import BoxHeader from 'components/page/BoxHeader';

const ResultLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex-grow: 1;
  }

  .image {
    overflow: hidden;

    img {
      border-radius: 50%;
      max-width: 400px;
      height: auto;
    }
  }
`;

const ResultImage = ({ url, alt = 'profile' }) => (
  <div className="image">
    <img src={url} alt={alt} />
  </div>
);

const Item = ({ data }) => (
  <div className="column is-one-third-desktop is-half-tablet is-12-mobile">
    <a href={data.link} target="_blank">
      <img src={data.image} alt={data.link} />
    </a>
  </div>
);

const ResultsByPost = ({ data }) => {
  const { messages } = useI18n();

  console.log({ data });
  const {
    profileName,
    profileFollower,
    profileAverageEr,
    postComment,
    postLike,
    postAverageEr,
  } = data;

  return (
    <div>
      <Box>
        <BoxHeader title={`Risultati per post: ${data.postId}`} />
        <ResultLine>
          <ResultImage url={data.postImage} />
          <div>
            <Stats
              data={{
                profileName,
                profileFollower,
                profileAverageEr,
              }}
              loading={false}
              itemClassName="analyze"
            />
            <Stats
              data={{
                postComment,
                postLike,
                postAverageEr,
              }}
              loading={false}
              itemClassName="analyze"
            />
          </div>
        </ResultLine>
      </Box>
    </div>
  );
};

export default ResultsByPost;
