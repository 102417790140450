import React from 'react';
// import axios from 'axios';
// import { useQuery } from 'react-query';

// import * as URI from 'utils/endpoints';
import DisplayResultsView from './DisplayResults.view';

const DisplayResults = ({ simulationResults, social, theme, contentTypes }) => {


  // if (query.isError || query.isLoading) {
  //   return null;
  // }

  // const { duration, contentType, kpi, social, theme } = query.data.data.data;
  // console.log('data:', query.data.data.data)
  return (
    <>
      <DisplayResultsView
        simulationResults={simulationResults}
        socials={social}
        themes={theme}
        contentTypes={contentTypes}
      />
    </>
  );
};

export default DisplayResults;
