import React from 'react';
import { useSelector } from 'react-redux';
import Color from 'color';
import { get, isEmpty } from 'lodash';
// import 'chartjs-plugin-zoom';

import ProgressBar from 'components/ProgressBar';
import NoDataMessage from 'components/page/NoDataMessage';
import View from './Chart2.view';
import { chartColors } from 'utils/chartConfig';

const c = Object.values(chartColors);
const rgbs = [...c, ...c, ...c, ...c, ...c, ...c, ...c, ...c];

const Chart2 = () => {
  const rawData = useSelector(state => state.charts.chart2);
  const loading = useSelector(state => state.charts.chart2Status);

  let data = get(rawData, 'data');
  let height = 0;
  let chartData = {};

  if (data && !isEmpty(data) && !loading) {
    const labels = Object.keys(data);
    const updatedLabels = labels.map(label =>
      label === 'null' ? 'NonDisp' : label
    );

    const datasets = [
      {
        data: Object.values(data),
        borderWidth: 1,
        backgroundColor: labels.map((item, i) =>
          Color(rgbs[i]).alpha(0.5).string()
        ),
        borderColor: labels.map((item, i) => Color(rgbs[i]).string()),
      },
    ];

    height = 300; // + (labels.length / 4) * 15
    chartData = { labels: updatedLabels, datasets };
  }

  if (isEmpty(data) && !loading) return <NoDataMessage />;

  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : (
        <View data={chartData} height={height} redraw={true} />
      )}
    </div>
  );
};

export default Chart2;
