import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useI18n } from 'contexts/I18nProvider';
import Welcome2 from './Welcome2';

const Wrapper = styled.div`
  color: #fff;

  .item {
    height: 100%;
    border-radius: ${props => props.theme.border.radius};
    padding: ${props => props.theme.gap};
    color: #fff;

    a {
      color: #fff;
    }

    &.analyze {
      display: flex;
      line-height: 1.125;

      .is-128x128 {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .is-4 {
    .item {
      background-color: ${props => props.theme.colors.pink};
    }
  }

  .is-8 {
    .item {
      background-color: ${props => props.theme.colors.blue};
    }
  }
`;

const Options = () => {
  const { messages } = useI18n();

  return (
    <Wrapper className="columns">
      <div className="column is-8 p-3">
        <div className="item">
          {/* <div className="col-title is-size-4 mb-4 has-text-weight-bold">{messages.rankitWatcherTitleString}</div>
            <p className="mb-3">{messages.rankitWatcherDescriptionString}</p> */}
          <Welcome2 />
        </div>
      </div>
      <div className="column is-4 p-3">
        <div className="item analyze p-3">
          <div>
            <Link to="/">
              <div className="col-title is-size-4 mb-2 has-text-weight-bold">
                {messages.rankitTiktokTitleString}
              </div>
              <small>{messages.rankitTiktokDescriptionString}</small>
            </Link>
          </div>
          <div>
            <Link to="/">
              <figure className="media-right">
                <div className="image is-128x128">
                  <img
                    src="/images/analyze-tiktok-logo-128.jpg"
                    alt="Analyze TikTok"
                  />
                </div>
              </figure>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Options;
