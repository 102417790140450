import React from 'react';
import { useSelector } from 'react-redux';
import Color from 'color';
import { get, isEmpty } from 'lodash';
// import 'chartjs-plugin-zoom';

import ProgressBar from 'components/ProgressBar';
import NoDataMessage from 'components/page/NoDataMessage';
import View from './Chart5.view';
import { useCampaignFiltersContext } from 'contexts/CampaignFiltersProvider';
import { rgbs } from 'utils/chartConfig';
// import { chartColors } from 'utils/chartConfig';
// import { useI18n } from 'contexts/I18nProvider';

// RADAR CHART OPTIONS
// const options = {
//   scale: {
//     ticks: { beginAtZero: true },
//   },
//   tooltips: {
//     callbacks: {
//       title: (item, data) => {
//         // console.log({item, data})
//         const label = data.labels[item[0].index] || '-';
//         return label;
//       },
//     },
//   },
// };

// RADAR CHART DATA
// if(data && !isEmpty(data) && !loading) {
//   const labels = Object.keys(data)
//   const values = Object.values(data)
//   const updatedLabels = labels.map(label => label === 'null' ? 'NonDisp' : label)
// const isLead = get(ctx, 'filters.kpi') === 'lead';
//   const title =  isLead ? messages.trafficSourceLabelLeadString : messages.trafficSourceLabelClickString

//   const color = Color(rgbs[0])
//   const datasets = [{
//     label: title,
//     data: values,
//     backgroundColor: color.alpha(0.5).string(),
//     borderColor: color.string(),
//     borderWidth: 1,
//   }]
//   chartData = {labels: updatedLabels, datasets}
// }
const height = 300;

const Chart5 = () => {
  const rawData = useSelector(state => state.charts.chart5);
  const loading = useSelector(state => state.charts.chart0Status);
  const ctx = useCampaignFiltersContext();

  let data = get(rawData, 'data');
  let chartData = {};

  if (!loading) {
    if (isEmpty(data)) return <NoDataMessage />;

    if (data && !isEmpty(data)) {
      const labels = Object.keys(data);
      const colors = rgbs(labels.length);

      // const datasets = Object.keys(data).map((label, index) => {
      //   return {
      //     label,
      //     data: [data[label]],
      //     backgroundColor: [Color(colors[index]).alpha(0.5).string()],
      //     borderColor: Color(colors[index]).string(),
      //     borderWidth: 1,
      //   };
      // });

      const datasets = [
        {
          label: false, // `Totale ${rawData.total}`,
          data: Object.values(data),
          backgroundColor: labels.map((item, index) =>
            Color(colors[index]).alpha(0.5).string()
          ),
          borderColor: labels.map((item, index) =>
            Color(colors[index]).string()
          ),
          borderWidth: 1,
        },
      ];
      chartData = { labels, datasets };
    }
  }

  if (isEmpty(data) && !loading) return <NoDataMessage />;

  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : (
        <View
          data={chartData}
          options={{ legend: { display: false } }}
          height={height}
          redraw={true}
        />
      )}
    </div>
  );
};

export default Chart5;
