import React from 'react';
import qs from 'qs';
import { toast } from 'bulma-toast';

import { RANKIT_APP_USER } from 'utils/config';
import { logError } from 'services/api';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary', { error }, { errorInfo });
    try {
      const { username } = JSON.parse(
        window.localStorage.getItem(RANKIT_APP_USER)
      );
      const params = qs.stringify(
        {
          username,
          text: error.message,
        },
        { encode: false }
      );
      // log the error to an error reporting service
      toast({
        message: 'Si è verificato un errore.',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });

      process.env.NODE_ENV === 'production' && logError(params);
    } catch (error) {}
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>Si è verificato un errore in questa sezione.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
