import axios from 'axios';

import * as URI from 'utils/endpoints';
// import {RANKIT_APP_USER} from 'utils/config'

// function getUser(){
//   return window.localStorage.getItem(RANKIT_APP_USER)
// }
// function getUserField(name) {
//   return getUser()[name]
// }
// const userField = () => {
//   const user = window.localStorage.getItem(RANKIT_APP_USER)
//   return (field) =>

// }

// {'error':'motivo errore'}
// {'token':'.....'}
export const token = async () => {
  try {
    const result = await axios.get(URI.API_TOKEN);
    const { data } = result;
    // console.log('token', {data})
    return data; // .token
  } catch (error) {
    console.log({ error });
    return false;
  }
};

// "success: {""data"":{""advertiser_id"":23,""email"":""marco.picciolitestrankit99@mates4digital.com"",""id"":155,""username"":""marcop99""},""response"":""ok""}
// error: {'error': 'no user'}"
// "username: marco.picciolitestrankit99@mates4digital.com
// password: f7e01c99ac6ef11c385711a9cd799d6ce85d7323978487fc234075277d10a4a5
export const signin = async formData => {
  // console.log({ formData });
  try {
    const result = await axios.post(URI.API_SIGN_IN, formData);
    const { data } = result;
    // console.log(data.data);
    // console.log('signin', {data})
    return data;
  } catch (error) {
    console.log({ error });
    /**
     * if error causato dal token scaduto
     * 1 - token()
     * 2 - ripeto la chiamata signin(formData)
     *
     * concatena le chiamate async
     */
  }
};
export const signInWithCookie = async value => {
  try {
    const result = await axios.get(`${URI.API_SIGN_IN_DH}?dh=${value}`);
    const { data } = result;
    return data;
  } catch (error) {
    console.log({ error });
  }
};

// NOTE: DISMISSED
export const signout = async () => {
  const result = await axios.get(URI.API_SIGN_OUT);
  const { data } = result;
  return data;
};

export const resetPassword = async formData => {
  const result = await axios.get(URI.API_RESET_PWD, formData);
  const { data } = result;
  return data;
};

// "token: preso da /login
// user_id: preso da /signin
// advertiser_id: preso da /signin"
export const accountSummary = async () => {
  try {
    const result = await axios.get(URI.API_ACCOUNT_SUMMARY);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const homeQuery = async () => {
  // await new Promise(resolve => setTimeout(resolve, 1000))
  // if(true) throw new Error('erroraccio')
  const result = await axios.get(URI.API_ACCOUNT_SUMMARY);
  // const {data} = result
  return result;
};

/**
 *
 * @param {*} endpointsByRole
 */
export const filterGroups = async (endpointsByRole = URI) => {
  try {
    const result = await axios.get(endpointsByRole.API_FILTER_GROUPS);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const filterKpis = async () => {
  try {
    const result = await axios.get(URI.API_FILTER_KPIS);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const filterAnalyzeType = async () => {
  try {
    const result = await axios.get(URI.API_FILTER_ANALYZE_TYPE);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const filterTimeframe = async () => {
  try {
    const result = await axios.get(URI.API_FILTER_TIMEFRAME);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const tiktokAnalyze = async ({ key, type } /* formData */) => {
  try {
    const result = await axios.get(
      `${URI.API_TIKTOK_ANALYZE}?key=${key}&type=${type}`
    );
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
    // return error
  }
};

export const instagramAnalyze = async ({ key, type } /* formData */) => {
  try {
    const result = await axios.get(
      `${URI.API_INSTAGRAM_ANALYZE}?key=${key}&type=${type}`
    );
    const { data } = result;
    return data;
  } catch (error) {
    // console.log({error})
  }
};

export const chart0 = async (params, endpointsByRole = URI) => {
  try {
    const result = await axios.get(
      `${endpointsByRole.API_CHART_ONE}?${params}`
    );
    const { data } = result;
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const adminStats = async (params, timeframe) => {
  try {
    const result = await axios.get(`${URI.API_SUPERADMIN_STATS}?${params}`);
    const { data } = result;
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const chart2 = async (params, endpointsByRole = URI) => {
  try {
    const result = await axios.get(
      `${endpointsByRole.API_CHART_TWO}?${params}`
    );
    const { data } = result;
    return data;
  } catch (error) {
    // console.log(error)
  }
};
export const chart5 = async (params, endpointsByRole = URI) => {
  try {
    const result = await axios.get(
      `${endpointsByRole.API_CHART_FIVE}?${params}`
    );
    const { data } = result;
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const getLang = async params => {
  try {
    const result = await axios.get(`${URI.API_LANG}?${params}`);
    const { data } = result;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const logError = async params => {
  try {
    const result = axios.get(`${URI.APP_ERROR_REPORT}?${params}`);
    const { data } = result;
    return data;
  } catch (error) {
    console.log(error);
  }
};
