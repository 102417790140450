import React from 'react';
import styled from 'styled-components';

import Stats from 'components/page/Stats';
import { useI18n } from 'contexts/I18nProvider';
import Box from 'components/page/Box';
import BoxHeader from 'components/page/BoxHeader';
import Table from './Table.view';
import StatsAnalyze from 'components/page/StatsAnalyze';

const ResultLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .image {
    overflow: hidden;
    img {
      border-radius: 50%;
      max-width: 200px;
      height: auto;
    }
  }
`;

const ResultImage = ({ url, alt = 'profile' }) => (
  <div className="image mr-4">
    <img src={url} alt={alt} />
  </div>
);

const ResultsByProfile = ({ data, searchkey }) => {
  const { messages } = useI18n();

  const {
    profileFollower,
    profileFollowing,
    profilePostNumber,
    profileAverageEr,
  } = data;

  return (
    <div>
      <Box>
        <BoxHeader title={`Risultati per profilo: ${data.profileName}`} />
        <ResultLine>
          <div className="columns is-align-items-center">
            <div className="column is-half is-flex is-align-items-center">
              <ResultImage url={data.profileImageUrl} />
              <div>
                <h2>{`@${searchkey}`}</h2>
              </div>
            </div>
            <div className="column is-half">
              <StatsAnalyze
                data={{
                  profileFollower,
                  profileFollowing,
                  profilePostNumber,
                  profileAverageEr,
                }}
                loading={false}
              />
            </div>
          </div>
        </ResultLine>
      </Box>
      {data.profilePosts ? (
        <Box>
          <BoxHeader
            title="Post overview"
            subtitle={`Gli ultimi post pubblicati da ${data.profileName}`}
          />
          <Table data={data.profilePosts} />
        </Box>
      ) : null}
    </div>
  );
};

export default ResultsByProfile;
