import React from 'react';

const EmailButton = ({ children }) => (
  <a
    className="button"
    href="mailto:supporto@rankit.it?subject=Richiesta di contatto da un consulente"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

export default EmailButton;
