import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSession } from 'contexts/SessionProvider';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const session = useSession();

  return (
    <Route
      {...otherProps}
      render={props => {
        return session.user ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
