import React from 'react';
import styled from 'styled-components';

import StatsAnalyze from 'components/page/StatsAnalyze';
import { useI18n } from 'contexts/I18nProvider';
import Box from 'components/page/Box';
import BoxHeader from 'components/page/BoxHeader';
import Table from './Table.view';

const ResultLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex-grow: 1;
  }

  .image {
    overflow: hidden;
    img {
      border-radius: 50%;
      max-width: 200px;
      height: auto;
    }
  }
`;

const ResultImage = ({ url, alt = 'profile' }) => (
  <div className="image">
    <img src={url} alt={alt} />
  </div>
);

const Item = ({ data }) => (
  <div className="column is-one-third-desktop is-half-tablet is-12-mobile">
    <a href={data.link} target="_blank">
      <img src={data.image} alt={data.link} />
    </a>
  </div>
);

const ResultsByHashtag = ({ data, searchkey }) => {
  const { messages } = useI18n();
  const { tagFollower, tagFollowing, tagPostNumber, tagAverageEr } = data;

  return (
    <div>
      <Box>
        <BoxHeader title={`Risultati per hashtag: ${data.tagName}`} />
        <ResultLine>
          <div className="columns is-align-items-center">
            <div className="column is-half is-flex is-align-items-center">
              <ResultImage url={data.tagImageUrl} />
              <div>
                <h2>{`#${searchkey}`}</h2>
              </div>
            </div>
            <div className="column is-half">
              <StatsAnalyze
                data={{
                  tagFollower,
                  tagFollowing,
                  tagPostNumber,
                  tagAverageEr,
                }}
                loading={false}
                itemClassName="analyze"
              />
            </div>
          </div>
        </ResultLine>
      </Box>
      {data.tagPosts ? (
        <Box>
          <BoxHeader
            title="Post overview"
            subtitle={`Gli ultimi post pubblicati da ${data.tagName}`}
          />
          <Table data={data.tagPosts} />
        </Box>
      ) : null}
    </div>
  );
};

export default ResultsByHashtag;
