import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  instagramAnalyze,
  tiktokAnalyze,
  filterAnalyzeType,
} from 'services/api';
import Box from 'components/page/Box';
import BoxHeader from 'components/page/BoxHeader';
import Stats from 'components/page/Stats';
import FieldErrorMessage from 'components/page/FieldErrorMessage';
import { useI18n } from 'contexts/I18nProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import SearchForm from './components/SearchForm';
import ResultsByHashtag from './components/ResultsByHashtag';
import ResultsByPost from './components/ResultsByPost';
import ResultsByProfile from './components/ResultsByProfile';
import { toast } from 'bulma-toast';

const endpointMap = {
  tiktok: tiktokAnalyze,
  instagram: instagramAnalyze,
};

const Title = ({ id }) => {
  const { messages } = useI18n();

  const title =
    id === 'instagram'
      ? messages.instagramAnalyzeTitle
      : messages.tiktokAnalyzeTitle;
  const subtitle =
    id === 'instagram'
      ? messages.instagramAnalyzeDescription
      : messages.tiktokAnalyzeDescription;

  return <BoxHeader title={title} subtitle={subtitle} />;
};

const AnalyzePage = ({ history, location, match }) => {
  const [data, setData] = useState();
  const [searchkey, seyKey] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, /* getValues, */ reset } = useForm();
  const { messages } = useI18n();

  useEffect(() => {
    try {
      async function getItems() {
        const res = await filterAnalyzeType();
        if (res?.data) setOptions(res.data);
      }

      getItems();
    } catch (error) {
      console.log({ error });
    }
  }, []);

  // console.log({ match });
  const { id = '' } = match.params;

  // const values = getValues()
  // const applyedFilters = Object.keys(values).reduce((str, key, index) => {
  //   // const post = (index < values.length - 1) ? ', ': ''
  //   return !!values[key] ? `${str} ${values[key]}` : `${str}`
  //   // return `${str} ${values[key]}`//  = "${values[key]}"${post}
  // }, '')

  // const options = {
  //   Profile: 'profile',
  //   Post: 'post',
  //   Hashtag: 'hashtag',
  // }

  const onSubmit = async ({ type, key = '' }) => {
    // goto 'type' view
    // history.push(`${match.url}/${type}`);
    // load data
    setLoading(true);
    seyKey(key);
    // fix the case of key === undefined

    // console.log({ response });

    // if (response.error) {
    //   setErrorMessage('Dati non disponibili.');
    //   return;
    // }
    try {
      const response = await endpointMap[id]({ type, key });
      const { data, error } = response;
      if (data) {
        setData(data);
        setSelectedType(type);
        setErrorMessage('');
      }
      if (error) {
        setData(undefined);
        setSelectedType('');
        // setErrorMessage('Si è verificato un errore. Riprova più tardi');
        toast({
          message: 'Si è verificato un errore. Riprova più tardi.',
          type: 'is-danger',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' },
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box classnames="has-text-white has-blue-bg">
        <Title id={id} />
      </Box>
      <Box classnames="is-shadowless">
        <SearchForm
          options={options}
          onSubmit={onSubmit}
          setErrorMessage={setErrorMessage}
          setData={setData}
          loading={loading}
        />
      </Box>
      <Box classnames="is-shadowless">
        {data && <BoxHeader title={`${messages.instagramAnalyzeResults}`} />}
        {/*  subtitle={`Filtri: [${applyedFilters}]`} */}
        {/* {Object.keys(data).length > 0 && <Stats data={data} itemClassName="analyze"/>} */}
        {<FieldErrorMessage msg={errorMessage} />}

        <ErrorBoundary>
          {/* WAS ... */}
          {/* <Stats data={data} itemClassName="analyze" loading={loading}/> */}

          {/* NOW IS ... */}
          {/* <Route exact path="/analyze/:social/hashtag"> */}
          {data && selectedType === 'hashtag' && (
            <ResultsByHashtag data={data} searchkey={searchkey} />
          )}
          {/* </Route> */}

          {/* <Route exact path="/analyze/:social/post"> */}
          {data && selectedType === 'post' && (
            <ResultsByPost data={data} searchkey={searchkey} />
          )}
          {/* </Route> */}

          {/* <Route exact path="/analyze/:social/profile"> */}
          {data && selectedType === 'profile' && (
            <ResultsByProfile data={data} searchkey={searchkey} />
          )}
          {/* </Route> */}
        </ErrorBoundary>
      </Box>
    </div>
  );
};

export default AnalyzePage;
