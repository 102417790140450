import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .box-header-title {
    display: flex;
    align-items: center;
  }
`;

const BoxHeader = ({ icon, title, subtitle }) => {
  return (
    <Wrapper className="mb-4">
      {title && (
        <div className="box-header-title is-size-5 has-text-weight-bold mb-2">
          {icon && <span className="icon mr-2">{icon}</span>}
          {title}
        </div>
      )}
      {subtitle && <div>{subtitle}</div>}
    </Wrapper>
  );
};

export default BoxHeader;
