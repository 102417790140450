import React from 'react';
import { useSelector } from 'react-redux';
import Color from 'color';
import { get, isEmpty, pick } from 'lodash';
import { format } from 'date-fns';

import {
  useCampaignFiltersContext,
  CampaignFiltersContext,
} from 'contexts/CampaignFiltersProvider';
import { useI18n } from 'contexts/I18nProvider';
import ProgressBar from 'components/ProgressBar';
import NoDataMessage from 'components/page/NoDataMessage';
import View from './ChartBar.view';
import { rgbs } from 'utils/chartConfig';
import { isLabelFormattable } from 'utils/dates';

const height = 460; //  + (labels.length / 4) * 35

const ChartBar = () => {
  const { filters } = useCampaignFiltersContext(CampaignFiltersContext);
  const { dateFormat } = useI18n();
  const rawData = useSelector(state => state.charts.chart0);
  const loading = useSelector(state => state.charts.chart0Status);

  const isFormattable = isLabelFormattable(filters.timeframe);

  let data = get(rawData, 'data');
  let chartData = {};

  if (!loading) {
    if (isEmpty(data)) return <NoDataMessage />;

    if (data && !isEmpty(data)) {
      const keys = Object.keys(data);
      const firstItemData = data[keys[0]].data;
      const [one, two, three, four, five, a, b, ...others] = Object.keys(
        firstItemData
      );
      // const useOnlyTheseData = [a, b];
      const useOnlyTheseData = { [a]: firstItemData[a], [b]: firstItemData[b] }; // pick(firstItemData, [a, b]);

      const labels = Object.keys(useOnlyTheseData).map(key =>
        isFormattable ? key : format(new Date(key), dateFormat)
      );
      const colors = rgbs(labels.length);
      const values = Object.values(useOnlyTheseData);

      chartData = {
        labels,
        datasets: [
          {
            data: [values[0], values[1]],
            backgroundColor: [
              Color(colors[0]).string(),
              Color(colors[1]).string(),
            ],
            borderColor: [
              Color(colors[0]).alpha(0.5).string(),
              Color(colors[1]).alpha(0.5).string(),
            ],
          },
        ],
      };
    }
  }

  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : (
        <View
          data={chartData}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            layout: {
              padding: 0,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
          height={height}
          redraw={true}
        />
      )}
    </div>
  );
};

export default ChartBar;
