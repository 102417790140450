import React from 'react';
import styled from 'styled-components';
import pack from '../../package.json';

const Wrapper = styled.footer`
  margin-top: 32px;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.colors.greyBlue};

  a {
    color: #fff;
    transition: color ease 0.25s;
    &:hover {
      color: ${props => props.theme.colors.pink};
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper className="_footer">
      <div className="_container has-text-centered">
        <div>
          <small>frontend version: {pack.version}</small>
        </div>
        &copy; 2020{' '}
        <a
          href="https://www.mates4digital.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mates 4 Digital
        </a>{' '}
        - <a href="mailto:info@mates4digital.com">info@mates4digital.com</a>
      </div>
    </Wrapper>
  );
};

export default Footer;
