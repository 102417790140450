import React from 'react'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'

import {useSession} from 'contexts/SessionProvider'
import FieldErrorMessage from '../../components/page/FieldErrorMessage'

const SigninForm = () => { 
  const {register, handleSubmit, errors} = useForm()
  const {resetPassword} = useSession()

  const onSubmit = data => {
    resetPassword(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">Email {errors.email && <FieldErrorMessage msg={errors.email.message}/>}</label>
        <div className="control">
          <input 
            name="email" 
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "email non valida"
              }
            })} 
            className="input" 
            type="email" 
          />
        </div>
      </div>
      <div className="field">
        <p className="control">
          <button type="submit" className="button is-info">
            Reset password
          </button>
        </p>
      </div>
      <div>
        <Link to="/login">Torna al login.</Link>        
      </div>
    </form>
  )
}

export default SigninForm
