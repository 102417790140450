const DRIVER = '_driver';
const SUPERADMIN = '_superadmin';
const advGuest = [5, 8];

// endpoints based on user role
function doJob(endpoints, suffix) {
  return {
    API_ACCOUNT_SUMMARY: `${endpoints.API_ACCOUNT_SUMMARY}${suffix}`,
    API_FILTER_GROUPS: `${endpoints.API_FILTER_GROUPS}${suffix}`,
    API_CHART_ONE: `${endpoints.API_CHART_ONE}${suffix}`,
    API_CHART_TWO: `${endpoints.API_CHART_TWO}${suffix}`,
    API_CHART_FIVE: `${endpoints.API_CHART_FIVE}${suffix}`,
  };
}

export const API_BASE_URL = process.env.REACT_APP_API_URI; // `https://api.rankit.it`;
/** token */
export const API_TOKEN = `${API_BASE_URL}/login`;
/** login */
export const API_SIGN_IN = `${API_BASE_URL}/signin`;
export const API_SIGN_IN_DH = `${API_BASE_URL}/signin_dh`;
export const API_SIGN_OUT = `${API_BASE_URL}/signout`;
export const API_RESET_PWD = `${API_BASE_URL}/reset-password`;
/** */
export const API_ACCOUNT_SUMMARY = `${API_BASE_URL}/account_summary`;
/** */
export const API_FILTER_ANALYZE_TYPE = `${API_BASE_URL}/filter_analyze_type`;
export const API_TIKTOK_ANALYZE = `${API_BASE_URL}/tiktok_analyze`;
export const API_INSTAGRAM_ANALYZE = `${API_BASE_URL}/instagram_analyze`;
/** */
export const API_FILTER_GROUPS = `${API_BASE_URL}/filter_groups_and_campaigns`;
export const API_FILTER_KPIS = `${API_BASE_URL}/filter_kpis`;
export const API_FILTER_TIMEFRAME = `${API_BASE_URL}/filter_timeframe`;
/** */
export const API_CHART_ONE = `${API_BASE_URL}/chart_one`;
export const API_CHART_TWO = `${API_BASE_URL}/chart_two`;
export const API_CHART_FIVE = `${API_BASE_URL}/chart_five`;
/** */
export const APP_ERROR_REPORT = `${API_BASE_URL}/log_error`;
/** export */
export const API_EXPORT = `${API_BASE_URL}/export`;
/** */
export const API_LANG = `${API_BASE_URL}/app_lang`;
/** */
export const API_CAMPAIGN_SIMULATOR_INPUT = `${API_BASE_URL}/cmp_sim_dropdowns`;
export const API_CAMPAIGN_SIMULATOR_INPUT_2 = `${API_BASE_URL}/cmp_sim_dropdowns_2`;
export const API_CAMPAIGN_SIMULATOR_CALC = `${API_BASE_URL}/cmp_sim_calc`;
export const API_CAMPAIGN_SIMULATOR_SAVE = `${API_BASE_URL}/cmp_sim_save`;
export const API_CAMPAIGN_SIMULATOR_SEND = `${API_BASE_URL}/cmp_sim_send`;

export const API_SUPERADMIN_STATS = `${API_BASE_URL}/stats`;

/** */
export function createEndpointsByRole(endpoints, user) {
  // no user || advertiser/guest, use default
  if (!user || advGuest.includes(user.user_group_id)) return null;

  // update based on user role
  const updatedEps =
    user.user_group_id === 1
      ? doJob(endpoints, SUPERADMIN)
      : doJob(endpoints, DRIVER);

  return { ...endpoints, ...updatedEps };
}

export function createEndpointsByRoleFORHOME(user) {
  if (!user || advGuest.includes(user.user_group_id))
    return API_ACCOUNT_SUMMARY;
  const url =
    user.user_group_id === 1
      ? `${API_ACCOUNT_SUMMARY}${SUPERADMIN}`
      : `${API_ACCOUNT_SUMMARY}${DRIVER}`;

  return url;
}
