import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.small`
  color: red;
  font-weight: 500;
`;

const FieldErrorMessage = ({ msg }) => {
  return msg ? <Wrapper>{msg}</Wrapper> : null;
};

export default FieldErrorMessage;
