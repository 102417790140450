import { useEffect, useState, useRef } from 'react';

export default function useLocalStorageState(
  key,
  defaultValue = null,
  options = { serialize: JSON.stringify, deserialize: JSON.parse }
) {
  if (!key) throw new Error(`useLocalStorageState, missing key parameter`);

  const { serialize, deserialize } = options;

  const [state, setState] = useState(() => {
    const state = window.localStorage.getItem(key);
    return state // (state && state !== 'undefined')
      ? deserialize(state)
      : typeof defaultValue === 'function'
      ? defaultValue()
      : defaultValue;
  });

  // if "key" changes I want to delete the previous key from local storage
  const prevKeyRef = useRef(key);

  // keep localStorage in sync
  useEffect(() => {
    if (prevKeyRef.current !== key) {
      localStorage.removeItem(prevKeyRef.current);
    }

    if (state) window.localStorage.setItem(key, serialize(state));
    else localStorage.removeItem(key);
  }, [key, state, serialize]);

  return [state, setState];
}
