import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .inbox {
    width: 98%;
    _max-width: 1180px;
    margin: 0 auto;
    _padding: 16px 0;
  }
`

const Box = ({children, classnames = ''}) => {
  return (
    <Wrapper className={`box ${classnames}`}>
      <div className="inbox">
        {children}
      </div>
    </Wrapper>
  )
}

export default Box
