import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useI18n } from 'contexts/I18nProvider';

const FormWrapper = styled.form`
  @media (min-width: 768px) {
    display: flex;

    .field {
      margin-right: ${props => props.theme.gap};
      margin-bottom: 0;

      &.is-grouped {
        align-items: flex-end;
      }

      select,
      .select {
        width: 100%;
      }
    }
  }

  small {
    font-weight: 500;
  }
`;

const SearchForm = ({
  options,
  onSubmit,
  setErrorMessage,
  setData,
  loading,
}) => {
  const { register, handleSubmit, getValues, reset } = useForm();
  const { messages } = useI18n();

  const map = {
    post: messages.instagramAnalyzeFilterTypePost,
    profile: messages.instagramAnalyzeFilterTypeProfile,
    hashtag: messages.instagramAnalyzeFilterTypeHashtag,
  };
  const mapOptionName = (name = '') => {
    return map[name.toLowerCase()];
  };

  console.log(getValues());

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        {
          <label className="label">
            {messages.instagramAnalyzeFilterKeyword}
          </label>
        }
        <div className="control">
          <input
            ref={register}
            name="key"
            className="input"
            type="text"
            placeholder="keyword"
          />
        </div>
      </div>
      <div className="field">
        {<label className="label">{messages.instagramAnalyzeFilterType}</label>}
        <div className="control">
          <div className="select">
            <select ref={register} name="type">
              {options.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {mapOptionName(opt.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button type="submit" className="button is-link" disabled={loading}>
            {loading
              ? 'loading data'
              : messages.instagramAnalyzeFilterButtonApply}
          </button>
        </div>
        <div className="control">
          <button
            type="reset"
            className="button is-link is-light"
            disabled={loading}
            onClick={() => {
              reset();
              setData(undefined);
              setErrorMessage('');
            }}
          >
            {messages.instagramAnalyzeFilterButtonCancel}
          </button>
        </div>
      </div>
    </FormWrapper>
  );
};

export default SearchForm;
