import React from 'react';
import styled from 'styled-components';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Nav from 'components/Nav';
import NavProvider from 'contexts/NavProvider';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container {
    width: 100%;
    max-width: 1152px;
    flex-grow: 1;
  }

  @media all {
    .page-break,
    .only-print {
      display: none;
    }
  }

  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;
    }

    .page-break {
      display: block;
      page-break-after: always;
    }
    .only-print {
      display: flex;
    }
    .only-print-logo {
      justify-content: center;
      margin-bottom: 16px;
    }
    .only-print-logo img {
      max-width: 96px;
    }

    header,
    footer,
    nav,
    .hide-for-print {
      display: none;
    }

    .has-page-break {
      _page-break-after: always;
      _break-inside: avoid;
    }

    .half-size {
      width: 100%;
      height: auto;
    }

    .box {
      max-width: 100%;
      height: auto;
      break-inside: avoid;
      padding: 0;
      _border: 1px solid blue;

      .inbox {
        width: 100%;
        _border: 1px solid green;

        .chartjs-render-monitor {
          display: block;
          margin: auto;
          _border: 1px solid red;
          _height: 300px !important;
        }
      }
    }

    .print-columns-to-block {
      display: flex;
      flex-direction: column;
      break-inside: avoid-page;

      .column {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0;

        .box {
          height: auto;
          break-inside: avoid;
          _border: 1px solid blue;
          padding: 0;
          _break-before: always;
          _break-inside: avoid;

          .inbox {
            width: 100%;
            _border: 1px solid green;

            .chartjs-render-monitor {
              display: block;
              margin: auto;
              _border: 1px solid red;
              _height: 300px !important;
            }
          }
        }
      }

      .to-print {
        break-before: always;
        break-inside: avoid;
      }
    }

    .box {
      box-shadow: 0 0;
    }

    .rs-table-row-header .rs-table-cell {
      background-color: rgb(46, 165, 220) !important;
      color: #fff;
      font-weight: 700;
    }
    .rs-table-cell-header-icon-sort,
    .rs-table-cell-header-icon-sort-desc::after,
    .rs-table-cell-header-icon-sort-asc::after {
      color: #fff;
    }
    .rs-table-cell-header .rs-table-cell-content {
      font-size: 1rem !important;
      line-height: 1 !important;
    }
  }
`;

const AppLayout = ({ children }) => {
  return (
    <Wrapper>
      <NavProvider>
        <Header />
        <div className="container">{children}</div>
        <Footer />
        {/* <Nav/> */}
      </NavProvider>
    </Wrapper>
  );
};

export default AppLayout;
