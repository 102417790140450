import React from 'react';
import styled from 'styled-components';
// import {Link} from 'react-router-dom'

import { useI18n } from 'contexts/I18nProvider';
import EmailButton from 'components/page/EmailButton';

const Wrapper = styled.article`
  width: 98%;
  max-width: 1180px;
  margin: auto;
  color: ${props => props.theme.colors.blue};
  background-color: ${props => props.theme.colors.white};

  a {
    color: #fff;
    border: 0 none;
    background-color: ${props => props.theme.colors.blue};
  }
`;

const Welcome = () => {
  const { messages } = useI18n();

  return (
    <Wrapper className="media">
      <div className="media-content">
        <div className="content">
          <div className="is-size-4 has-text-weight-bold">
            {messages.rankitCampaignTitle}
          </div>
          <p>{messages.rankitCampaignDescription}</p>
          <EmailButton>{messages.rankitCampaignButtonString}</EmailButton>
        </div>
      </div>
      <figure className="media-right">
        <div className="image is-96x96">
          <img src="/images/Frankit-resized.png" alt="Frankit" />
        </div>
      </figure>
    </Wrapper>
  );
};

export default Welcome;
