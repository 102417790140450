import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import {useQuery} from 'react-query'
import { get, upperFirst } from 'lodash';
import { AiFillPieChart, AiOutlineDownload } from 'react-icons/ai';
import { BsInfoSquareFill } from 'react-icons/bs';
// import {FaUser} from 'react-icons/fa'
import styled from 'styled-components';

import { types } from 'store/middlewares/lifecycle';
import CampaignFiltersProvider, {
  useCampaignFiltersContext,
} from 'contexts/CampaignFiltersProvider';
import { useI18n } from 'contexts/I18nProvider';
import { useSession } from 'contexts/SessionProvider';
import Box from 'components/page/Box';
import ErrorBoundary from 'components/ErrorBoundary';
// import Stats from 'components/page/Stats'
import BoxHeader from 'components/page/BoxHeader';
// import WelcomeBox from 'components/page/WelcomeBox'
import Table from './components/Table.container';
import SuperAdminTable from './components/SuperAdminTable.container';
import Filters from './components/Filters';
import Chart0 from './components/Chart0.container';
import Chart2 from './components/Chart2.container';
import Chart5 from './components/Chart5.container';
import Chart6 from './components/Chart6.container';
import ChartBar from './components/ChartBar.container';
import FiltersInfo from './components/FiltersInfo';
import Downloads from './components/Downloads';

// NOTE: downloads can be a fixed toolbar on the right of the screen

const Wrapper = styled.div`
  .column {
    .box {
      height: 100%;
    }
  }

  .downloads {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 16px;

    > div {
      _margin-right: 16px;
    }
  }
`;

const needChart0 = ['click', 'lead', 'reach', 'impression'];
const hideChart0 = ['reach', 'impression'];
const needChart2 = ['click', 'lead'];
const needChart5 = ['click', 'lead'];
const needChart6 = ['lead'];
const needChartBar = ['reach', 'impression'];

const hasChart = kpi => list => list.includes(kpi);

const interval = 4000; //1000 * 60 * 2; // 2 minutes

const Campaign = () => {
  const ctx = useCampaignFiltersContext();
  const { getEndpointsByRole } = useSession();
  const { messages } = useI18n();
  const [time, setTime] = useState(0);
  // const queryInfo = useQuery('home')
  // const data = get(queryInfo, 'data.data.data', {})

  const dispatch = useDispatch();

  // #######################################
  // stop endpoint calls on component mount
  // #######################################

  useEffect(() => {
    dispatch({
      type: types.ON_MOUNT,
      payload: { page: 'campaign', endpointsByRole: getEndpointsByRole() },
    });
    // async function foo() {
    //   await setTimeout(setTime, interval, 1);
    // }
    // foo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time > 0) {
      console.log({ time });
      dispatch({
        type: types.ON_MOUNT,
        payload: { page: 'campaign', endpointsByRole: getEndpointsByRole() },
      });
      // async function foo() {
      //   await setTimeout(setTime, interval, time + 1);
      // }
      // foo();
    }
  }, [time]);

  const isLead = get(ctx, 'filters.kpi') === 'lead';

  const byKPI = hasChart(get(ctx, 'filters.kpi'));

  // const hasChart2 = get(ctx, 'filters.kpi') === 'lead';
  // const hasChart5 = get(ctx, 'filters.kpi') === 'lead';
  // const hasChart6 = get(ctx, 'filters.kpi') === 'lead';
  // console.log({ ctx });

  return (
    <Wrapper>
      <div className="only-print only-print-logo">
        <img src="/images/RankitLogo.png" alt="logo for print page only" />
      </div>
      {/* <Box classnames="has-text-white has-blue-bg">
        <WelcomeBox/>
      </Box> */}
      <Box classnames="hide-for-print">
        <BoxHeader
          icon={<AiFillPieChart />}
          title={messages.selectCampaignTitleString}
        />
        <ErrorBoundary>
          <Filters />
        </ErrorBoundary>
      </Box>
      <div className="columns">
        <div className="column is-8">
          <Box classnames="_has-page-break">
            <BoxHeader
              icon={<BsInfoSquareFill className="has-text-info" />}
              title={messages.summaryTitleString}
            />
            <FiltersInfo />
          </Box>
        </div>
        <div className="column is-4 hide-for-print">
          <Box>
            <BoxHeader
              icon={<AiOutlineDownload />}
              title={messages.downloadTitleString}
            />
            <Downloads />
          </Box>
        </div>
      </div>
      {/* <Box classnames="is-shadowless">
        <BoxHeader 
          icon={<FaUser/>} 
          title="Riepilogo del tuo account"
        />
        <Stats 
          data={data} 
          loading={queryInfo.isLoading}
          error={queryInfo.isError}
          itemClassName="campaign" 
        />
      </Box> */}
      <Box classnames="is-shadowless _hide-for-print pt-0 pb-0 mb-0">
        <ErrorBoundary>
          <Table containerClasses="pt-5 pb-5 mb-5" />
        </ErrorBoundary>
      </Box>
      {/* superadmin table */}
      <Box classnames="is-shadowless _hide-for-print pt-0 pb-0 mb-0">
        <ErrorBoundary>
          <SuperAdminTable containerClasses="pt-5 pb-5 mb-5" />
        </ErrorBoundary>
      </Box>
      <div className="page-break" />
      {/* chart 0 */}
      {byKPI(hideChart0) && ctx.filters.timeframe === 'yesterday' ? null : (
        <Box classnames="has-page-break">
          <BoxHeader
            icon={<AiFillPieChart />}
            title={`${upperFirst(get(ctx, 'filters.kpi', ''))} performance`}
          />
          <ErrorBoundary>
            <Chart0 />
          </ErrorBoundary>
        </Box>
      )}
      {/* START */}
      {byKPI(needChartBar) && get(ctx, 'filters.timeframe') === 'yesterday' ? (
        <Box classnames="_has-page-break">
          <BoxHeader
            icon={<AiFillPieChart />}
            title={`${upperFirst(get(ctx, 'filters.kpi', ''))} performance`}
          />
          <ErrorBoundary>
            <ChartBar />
          </ErrorBoundary>
        </Box>
      ) : null}
      {/* END */}
      <div className="page-break" />
      {/* chart 6 */}
      {byKPI(needChart6) ? (
        <>
          <Box classnames="_to-print">
            <BoxHeader icon={<AiFillPieChart />} title="Click-out" />
            <ErrorBoundary>
              <Chart6 />
            </ErrorBoundary>
          </Box>
          <div className="page-break" />
        </>
      ) : null}
      {/* chart 2 - 5*/}
      <div className="columns print-columns-to-block _hide-for-print">
        {byKPI(needChart2) && (
          <div className="column is-6 _has-page-break ">
            <div className="page-break" />
            <Box>
              <BoxHeader
                icon={<AiFillPieChart />}
                title={messages.geoDistributionTitleString}
              />
              <ErrorBoundary>
                <Chart2 />
              </ErrorBoundary>
            </Box>
          </div>
        )}
        {byKPI(needChart5) && (
          <div className="column is-6  _has-page-break">
            <div className="page-break" />
            <Box>
              <BoxHeader
                icon={<AiFillPieChart />}
                title={messages.trafficSourceTitleString}
              />
              <ErrorBoundary>
                <Chart5 />
              </ErrorBoundary>
            </Box>
          </div>
        )}
      </div>

      {/* chart 2 - 3 */}
      {/* <div className="columns">
        <div className="column is-4">
          <Box>
            <BoxHeader icon={<AiFillPieChart/>} title="Geo distribution" />
            <Chart2 />
          </Box>
        </div>
        <div className="column is-8">
          <Box>
            <BoxHeader icon={<AiFillPieChart/>} title="Campaign CTR over time" />
            
          </Box>
        </div>
      </div> */}
      {/* chart 4 - 5 */}
      {/* <div className="columns">
        <div className="column is-8">
          <Box>
            <BoxHeader icon={<AiFillPieChart/>} title="Reach by post over time" />
            
          </Box>
        </div>
        <div className="column is-4">
          <Box>
            <BoxHeader icon={<AiFillPieChart/>} title="Traffic by source" />
            <Chart5 />
          </Box>
        </div>
      </div> */}
      {/* chart 6 */}
      {/* <Box>
        <BoxHeader icon={<AiFillPieChart/>} title="Total lead" />        
      </Box> */}
    </Wrapper>
  );
};

const PageWithContextProvider = () => (
  <CampaignFiltersProvider>
    <Campaign />
  </CampaignFiltersProvider>
);

export default PageWithContextProvider;
