import {createReducer} from '@reduxjs/toolkit'

import {types as lifecycleTypes} from 'store/reducers/types'

const initialValues = {}

export const types = {
  ON_DATA: 'ON_DATA'
}

export default createReducer(initialValues, {
  [types.ON_DATA]: (state, action) => action.payload,
  [lifecycleTypes.ON_LOGOUT]: (state, action)  => initialValues,
})