import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { format } from 'date-fns';

import View from './SuperAdminTable.view';
import { useI18n } from 'contexts/I18nProvider';
import { useCampaignFiltersContext } from 'contexts/CampaignFiltersProvider';

const SuperAdminTable = ({ containerClasses = '' }) => {
  const rawData = useSelector(state => state.charts.adminTable);
  const loading = useSelector(state => state.charts.adminTableStatus);
  // const { dateFormat } = useI18n();
  // const ctx = useCampaignFiltersContext();
  //console.log({ ctx });

  let data = get(rawData, 'data');
  let tableData = null;

  if (data && !loading) {
    const keys = Object.keys(data);
    const baseLink = rawData.baseLink;

    tableData = keys.map(key => {
      const { brand, campaign, driver, post, hash, total } = data[key];

      return {
        brand,
        campaign,
        post,
        hash,
        total,
        driver,
        link: baseLink
          ? `${baseLink}/${hash}`
          : `https://taply.link/l/gossip/${hash}`,
      };
    });
  }

  return tableData && !isEmpty(tableData) ? (
    <div className={containerClasses}>
      <View data={tableData} loading={loading} />
    </div>
  ) : null;
};

export default SuperAdminTable;
