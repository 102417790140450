import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { get } from 'lodash';
import { subDays } from 'date-fns';
import styled from 'styled-components';
import { /* Input, */ DateRangePicker } from 'rsuite';

import * as dates from 'utils/dates';
import { CampaignFiltersContext } from 'contexts/CampaignFiltersProvider';
import { useI18n } from 'contexts/I18nProvider';
import Select from './Select';
import { useInterval } from 'utils/useInterval';

const {
  // allowedMaxDays,
  // allowedDays,
  // allowedRange,
  // beforeToday,
  afterToday,
  // combine
} = DateRangePicker;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;

  .field-daterange,
  .field-select {
    width: 100%;
    margin-bottom: ${props => props.theme.gap};
  }
  .button {
    margin-right: ${props => props.theme.gap};
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .controls-container {
      display: flex;
      flex-grow: 1;
    }

    .field-daterange,
    .field-select {
      max-width: 48%;
      margin-right: ${props => props.theme.gap};
    }
    .buttons-container {
      margin: 0;
    }
    .button {
      width: auto;
      margin-right: ${props => props.theme.gap};
    }
  }
`;
/** */
// const filterCampaignOptions = (allOptions) => (id) => {
//   if(id === '0') return [{id: '0', title: "Tutte"}, ...allOptions]

//   const options = allOptions.filter(option => (option.groupId === id || option.subgroupId === id))
//   return [{id: '0', title: 'Tutte'}, ...options]
// }
const removeTodayFilter = r => !dates.isToday(r.value);
const changeYesterdayValue = r => {
  if (dates.isYesterday(r.value)) {
    return { ...r, value: [subDays(new Date(), 6), new Date()] };
  }
  return r;
};
/**
 * modify ranges if kpi = impression | reach
 */
function updateLocallyDateRanges(localSelectedKpi, ranges = []) {
  // console.log({ localSelectedKpi });
  if (
    localSelectedKpi === '' ||
    localSelectedKpi === 'click' ||
    localSelectedKpi === 'lead'
  ) {
    return ranges;
  }
  // 1. remove oggi
  let updatedRanges = ranges.filter(removeTodayFilter);
  //.map(changeYesterdayValue);

  // console.log('remove oggi', { updatedRanges });
  return updatedRanges;
}
/** component */
const Filters = () => {
  const { messages } = useI18n();
  const { selectedGroup, ...ctx } = useContext(CampaignFiltersContext);
  const [localSelectedKpi, setLocalSelectedKpi] = useState('');
  const [localRanges, setLocalRanges] = useState([]);
  const { startInterval, stopInterval } = useInterval(1000 * 60 * 2);
  const _groupsOptions = useSelector(state =>
    get(state, 'campaign.groupsOptions', [])
  );
  // const allCampaignsOptions = useSelector(state => get(state, 'campaign.campaignsOptions', []))
  const kpisOptions = useSelector(state => {
    const temp = get(state, 'campaign.kpis.data', []);
    const map = {
      click: messages.selectCampaignKpiClickString,
      lead: messages.selectCampaignKpiLeadString,
      reach: messages.selectCampaignKpiReachString,
      impression: messages.selectCampaignKpiImpressionString,
    };
    const result = temp.map(({ value }) => ({ value, name: map[value] }));
    return result;
  });

  useEffect(() => {
    // startInterval(() => {
    //   console.log('on mount ctx.setFilters');
    //   ctx.setFilters();
    // });
    // reset filters onUnMount
    return () => ctx.setFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('>>>');
    setLocalRanges(updateLocallyDateRanges(localSelectedKpi, ctx.dateRanges));
  }, [localSelectedKpi]);

  // const startTimeout = () => {
  //   console.log('startTimeout');
  //   timeout.current = setTimeout(() => {
  //     console.log('ctx.setFilters', currentFormData);
  //     ctx.setFilters(currentFormData);
  //   }, 1000 * 5);
  // };

  /** useForm deconstruction */
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: ctx.defaultValues,
  });

  /** groups options */
  const groupsOptions = [
    { id: '0', name: messages.selectCampaignAllString },
    ..._groupsOptions,
  ];

  /** campaigns options */
  // const filterBy = filterCampaignOptions(allCampaignsOptions)
  // const filteredCampaignsOptions = filterBy(selectedGroup)

  /** form submit */
  const onSubmit = data => {
    startInterval(() => {
      ctx.setFilters(data);
    });
    ctx.setFilters(data);
  };
  const onReset = () => ctx.setFilters();

  // // console.log({ localSelectedKpi }, ctx.dateRanges);
  // console.log({ localSelectedKpi, localRanges });

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <div className="controls-container">
        {/* <Controller
          control={control}
          name="param_0"
          render={({value, name, ref, onChange}) => (
            <Input value={value} onChange={onChange} size="md" className="field-select"/>
          )}
        /> */}
        <Controller
          control={control}
          name="group"
          render={({ value, name, ref, onChange }) => (
            <Select
              data={groupsOptions}
              value={value}
              labelKey="name"
              valueKey="id"
              cleanHandler={() => {
                ctx.setSelectedGroup(ctx.defaultValues.group);
                setValue('group', ctx.defaultValues.group);
                setValue('campaign', ctx.defaultValues.campaign);
              }}
              changeHandler={value => {
                // NOTE: null comes from onClean and generated error in setSelectedGroup ...
                const _value = value || ctx.defaultValues.group;
                ctx.setSelectedGroup(_value);
                onChange(value);
              }}
            />
          )}
        />
        {/* <Controller
          control={control}
          name="campaign"
          render={({value, name, ref, onChange}) => (
            <Select
              data={filteredCampaignsOptions} 
              cleanable={false}
              value={value}
              labelKey="title"
              valueKey="id"
              cleanHandler={() => {
                setValue('campaign', ctx.defaultValues.campaign)
              }}
              onChange={onChange} 
            />
          )}
        />*/}
        <Controller
          control={control}
          name="kpi"
          render={({ value, name, ref, onChange }) => (
            <Select
              data={kpisOptions}
              value={value}
              labelKey="name"
              valueKey="value"
              cleanHandler={() => {
                setValue('kpi', 'click');
              }}
              changeHandler={value => {
                setLocalSelectedKpi(value);
                onChange(value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="timeframe"
          render={({ value, name, ref, onChange }) => (
            <DateRangePicker
              className="field-daterange"
              onClean={() => {
                setValue('timeframe', ctx.defaultValues.timeframe);
              }}
              disabledDate={afterToday()}
              onChange={onChange}
              value={value}
              size="md"
              ranges={localRanges}
              placement="auto"
            />
          )}
        />
      </div>
      <div className="buttons-container">
        <button className="button is-info" type="submit">
          {messages.selectCampaignButtonApplyString}
        </button>
        <button
          className="button"
          type="reset"
          onClick={() => {
            reset();
            onReset();
          }}
        >
          {messages.selectCampaignButtonCancelString}
        </button>
      </div>
    </FormWrapper>
  );
};

export default Filters;
