import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import qs from 'qs';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { toast } from 'bulma-toast';

import { RANKIT_APP_TOKEN, RANKIT_APP_USER } from 'utils/config';
import { API_EXPORT } from 'utils/endpoints';
import { CampaignFiltersContext } from 'contexts/CampaignFiltersProvider';
import { useI18n } from 'contexts/I18nProvider';

const Downloads = () => {
  const { messages } = useI18n();
  const [errorMessage, setErrorMessage] = useState('');
  const hasData0 = useSelector(
    state =>
      !isEmpty(get(state, 'charts.chart0.data', {})) &&
      !get(state, 'charts.chart0Status')
  );
  // const hasData2 = useSelector(
  //   state =>
  //     !isEmpty(get(state, 'charts.chart2.data', {})) &&
  //     !get(state, 'charts.chart2Status')
  // );
  // const hasData5 = useSelector(
  //   state =>
  //     !isEmpty(get(state, 'charts.chart5.data', {})) &&
  //     !get(state, 'charts.chart5Status')
  // );

  // const classnames = `has-tooltip-bottom has-tooltip-arrow ${
  //   hasData0 || hasData2 || hasData5 ? '' : 'link-disabled'
  // }`;

  const classnames = `has-tooltip-bottom has-tooltip-arrow ${
    hasData0 ? '' : 'link-disabled'
  }`;

  const { filters } = useContext(CampaignFiltersContext);
  let params = '';

  const getFileLocation = async url => {
    setErrorMessage('');
    try {
      const { data } = await axios.get(url);
      const { response, file } = data;
      if (response === 'ok' && file) {
        window.open(file, '_blank');
      } else {
        // setErrorMessage('Si è verificato un errore. Riprova più tardi');
        toast({
          message: 'Si è verificato un errore. Riprova più tardi',
          type: 'is-danger',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' },
        });
      }
    } catch (error) {
      // setErrorMessage('Si è verificato un errore. Riprova più tardi' /* error.message */);
      toast({
        message: 'Si è verificato un errore. Riprova più tardi',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' },
      });
    }
  };

  try {
    // ... add token in qs
    // const token = window.localStorage.getItem(RANKIT_APP_TOKEN);
    // ... add advertiser_id
    const user = JSON.parse(window.localStorage.getItem(RANKIT_APP_USER));
    // const advId = user ? user['advertiser_id'] : '';
    // const userId = user ? user['user_id'] : '';

    const p = {
      // token,
      // user_id: userId,
      // advertiser_id: advId,
      group: filters.group,
      campaign: filters.campaign,
      timeframe: filters.timeframe,
      kpi: filters.kpi,
      // OR ...filters
    };
    const qstr = qs.stringify(p, { encode: false });
    params = `?${qstr}`; // '?'+qs.stringify(p, {encode: false})
  } catch (error) {
    // console.log(error)
  }
  return (
    <div>
      <div className="downloads">
        {/* <a
          onClick={e => {
            e.preventDefault();
            getFileLocation(`${API_EXPORT}${params}&type=csv`);
          }}
          rel="noopener noreferrer"
          target="_blank"
          className={classnames}
          data-tooltip={`${messages.downloadCsvTooltipString}`}
        >
          <span className="icon">
            <FaFileCsv size="32px" color="#0a0" />
          </span>
        </a> */}
        <a
          onClick={e => {
            e.preventDefault();
            getFileLocation(`${API_EXPORT}${params}&type=xls`);
          }}
          rel="noopener noreferrer"
          target="_blank"
          className={classnames}
          data-tooltip={`${messages.downloadExcelTooltipString}`}
        >
          <span className="icon">
            <FaFileExcel size="32px" color="#080" />
          </span>
        </a>
        <a
          onClick={e => {
            e.preventDefault();
            window.print();
          }}
          className={classnames}
          data-tooltip={`${messages.downloadPdfTooltipString}`}
        >
          <span className="icon">
            <FaFilePdf size="32px" color="#f00" />
          </span>
        </a>
        {/* <button onClick={() => window.print()}>print</button>
      <div className={classnames} data-tooltip="Stampa i grafici o salva come pdf.">
        <span className="icon print-icon"  onClick={() => window.print()}><FaFilePdf size="16px" color="#f00"/></span>        
      </div> */}
      </div>
      <p style={{ color: 'red' }}>{errorMessage}</p>
    </div>
  );
};

export default Downloads;
