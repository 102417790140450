import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { format } from 'date-fns';

import View from './Table.view';
import { useI18n } from 'contexts/I18nProvider';
import { useCampaignFiltersContext } from 'contexts/CampaignFiltersProvider';

const Table = ({ containerClasses = '' }) => {
  const rawData = useSelector(state => state.charts.chart0);
  const loading = useSelector(state => state.charts.chart0Status);
  const { dateFormat } = useI18n();
  const ctx = useCampaignFiltersContext();
  //console.log({ ctx });

  let data = get(rawData, 'data');
  let hasClick = false;
  let hasLead = false;
  let hasReach = false;
  let hasImpression = false;
  let hasOffline = false;
  let tableData;

  //console.log({ data });

  if (data && !loading) {
    const kpi = get(ctx, 'filters.kpi', '@');
    const timeframe = get(ctx, 'filters.timeframe', '#');
    const keys = Object.keys(data);
    const dataInizio = format(new Date(rawData.date_range[0]), dateFormat);
    const dataFine = format(new Date(rawData.date_range[1]), dateFormat);
    // const dataFine =
    //   timeframe === 'yesterday' && (kpi === 'reach' || kpi === 'impression')
    //     ? format(Date.now() - 1000 * 60 * 60 * 24, dateFormat)
    //     : format(new Date(rawData.date_range[1]), dateFormat);
    tableData = keys.map(key => {
      const {
        brand,
        title,
        total_click,
        total_click2,
        total_lead,
        unique_users,
        total_impression,
        total_reach,
        total_click_offline,
      } = data[key];
      const inData = data[key].data;

      hasLead = !!total_lead;
      hasReach = !!total_reach;
      hasImpression = !!total_impression;
      hasClick = typeof total_click !== 'undefined';
      const values = Object.values(inData);
      const yesterdayValue = values[values.length - 2];
      // //console.log({ inData });
      //console.log({ kpi, timeframe, values, yesterdayValue });
      return {
        brand,
        title,
        total_click: hasLead ? total_click2 : total_click,
        total_click_offline,
        total_lead,
        total_impression:
          timeframe === 'yesterday' && kpi === 'impression'
            ? yesterdayValue
            : total_impression,
        unique_users,
        dataInizio,
        dataFine,
        total_reach:
          timeframe === 'yesterday' && kpi === 'reach'
            ? yesterdayValue
            : total_reach,
      };
    });
  }

  return tableData && !isEmpty(tableData) ? (
    <div className={containerClasses}>
      <View
        data={tableData}
        loading={loading}
        hasLead={hasLead}
        hasReach={hasReach}
        hasImpression={hasImpression}
        hasClick={hasClick}
      />
    </div>
  ) : null;
};

export default Table;
