import React /* , {useContext} */ from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RiLogoutBoxRLine } from 'react-icons/ri';
// import {GiHamburgerMenu} from 'react-icons/gi'
// import {AiOutlineClose} from 'react-icons/ai'

import { useI18n } from 'contexts/I18nProvider';
import { useSession } from 'contexts/SessionProvider';
// import {NavContext} from 'contexts/NavProvider'

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: ${props => props.theme.gap};
  margin-bottom: 1rem;
  background-color: ${props => props.theme.colors.greyBlue};
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,0.25), 0 0 0 1px rgba(10,10,10,.02);

  /* .navbar {
    color: #fff;
    background-color: ${props => props.theme.colors.greyBlue};

    .navbar-item {
      img {
        max-width: 96px;
        max-height: unset;
      }
    }
  } */

  .brand {
    img {
      max-width: 96px;
      max-height: unset;
    }
  }

  ._buttons {
    display: flex;
    align-items: center;
  }

  .button-logout {
    color: #fff;
    background-color: ${props => props.theme.colors.greyBlue};
    border: 0 none;
  }
`;

const Header = () => {
  const { user, signOut } = useSession();
  const { messages } = useI18n();
  // NOTE: remove comment to use menu in app
  // const {toggleNav, open} = useContext(NavContext)

  return (
    <Wrapper>
      <div className="brand">
        <Link to="/">
          <img src="/images/Rankit_Logo_Bianco.png" alt="logo Rankit" />
        </Link>
      </div>
      <div className="_buttons">
        {/* <button className="button button-logout has-tooltip-left has-tooltip-arrow" 
          onClick={toggleNav} 
          data-tooltip={`${open ? 'chiudi' : 'apri'} il menu`}
        >
          <span className="icon is-medium">{open ? <AiOutlineClose size="32px"/>: <GiHamburgerMenu size="32px"/>}</span>
        </button> */}
        <Link
          to="/"
          className="has-tooltip-bottom has-tooltip-arrow is-size-6 has-text-white"
          data-tooltip="Home"
        >
          Home
        </Link>
        {/* <Link
          to="/campaign_simulator"
          className="has-tooltip-bottom has-tooltip-arrow is-size-6 has-text-white ml-4"
          data-tooltip="Campaign simulator"
        >
          Simulazione campagna
        </Link> */}

        {user && (
          <button
            className="button button-logout has-tooltip-left has-tooltip-arrow ml-4"
            onClick={signOut}
            data-tooltip={`${messages.logoutButtonString}`}
          >
            <span className="icon is-medium">
              <RiLogoutBoxRLine size="32px" />
            </span>
          </button>
        )}
      </div>
      {/* <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src="/images/Rankit_Logo_Bianco.png" alt="logo Rankit"/>
          </Link>
          <button className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>        
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start"></div>
            <div className="navbar-end">
              <div className="navbar-item">
                <button className="button button-logout has-tooltip-left has-tooltip-arrow" onClick={toggleNav} data-tooltip={`${open ? 'chiudi' : 'apri'} il menu`}>
                  <span className="icon is-medium">{open ? <AiOutlineClose size="32px"/>: <GiHamburgerMenu size="32px"/>}</span>
                </button>
              </div>
              <div className="navbar-item">
                <div className="buttons">
                  {user && (
                    <button className="button button-logout has-tooltip-left has-tooltip-arrow" onClick={signOut} data-tooltip="LogOut">
                      <span className="icon is-medium"><RiLogoutBoxRLine size="32px"/></span>
                    </button>
                  )}
                </div>
              </div>
          </div>
        </div>
      </nav> */}
    </Wrapper>
  );
};

export default Header;
