import { createReducer } from '@reduxjs/toolkit';

import { types as lifecycleTypes } from 'store/reducers/types';

export const types = {
  ON_CHART_0: 'ON_CHART_0',
  ON_CHART_0_STATUS: 'ON_CHART_0_STATUS',
  ON_CHART_2: 'ON_CHART_2',
  ON_CHART_2_STATUS: 'ON_CHART_2_STATUS',
  ON_CHART_5: 'ON_CHART_5',
  ON_CHART_5_STATUS: 'ON_CHART_5_STATUS',
};

const initialValues = {};

export default createReducer(initialValues, {
  [types.ON_CHART_0]: (state, action) => ({ ...state, chart0: action.payload }),
  [types.ON_CHART_0_STATUS]: (state, action) => ({
    ...state,
    chart0Status: action.payload.loading,
  }),
  [types.ON_CHART_2]: (state, action) => ({ ...state, chart2: action.payload }),
  [types.ON_CHART_2_STATUS]: (state, action) => ({
    ...state,
    chart2Status: action.payload.loading,
  }),
  [types.ON_CHART_5]: (state, action) => ({ ...state, chart5: action.payload }),
  [types.ON_CHART_5_STATUS]: (state, action) => ({
    ...state,
    chart5Status: action.payload.loading,
  }),
  [lifecycleTypes.ON_LOGOUT]: (state, action) => initialValues,
  ADMIN_STATS_READY: (state, action) => ({
    ...state,
    adminTable: action.payload,
  }),
  ADMIN_STATS_STATUS: (state, action) => ({
    ...state,
    adminTableStatus: action.payload.loading,
  }),
});

/*
import {createReducer} from '@reduxjs/toolkit'

export const types = {
  ON_CHART_0: 'ON_CHART_0',
}

const initialValues = {
  chart0: {
    loading: false,
    chartData: {
      labels: [],
      datasets: []
    }
  }
}

export default createReducer(initialValues, {
  [types.ON_CHART_0]: (state, action) => {
    // console.log(action.payload)
    return {
      ...state, 
      chart0: {
        ...state.chart0,
        loading: action.payload.loading,
        chartData: {...action.payload.chartData},
      }
    }
  }
})


*/
