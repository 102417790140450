import React from 'react'
import {Doughnut} from 'react-chartjs-2'

const View = ({data, height}) => {
  return (
    <div style={{position: 'relative'}}>
      <Doughnut data={data} height={height} />
    </div>
  )
}

export default View
