export default {
  welcomeTitleString: 'Hello',
  welcomeDescriptionString:
    'Welcome to your dashboard. You will be able to monitor your campaigns in detail and use Rankit tools to analyze the performance of Social Media, intercept the best communities and evaluate the engagement rate together with the hashtags in the trend topics.',
  summaryTitleString: 'Selection recap',
  summaryOngoingString: 'ongoing',
  summaryCompletedString: 'completed',
  summaryUsersString: 'users',
  summaryDriversString: 'drivers',
  dashboardTitleString: 'Your campaigns dashboard',
  dashboardSubTitle1String: 'Campaigns management',
  dashboardSubDescription1String:
    'Follow the progress of your campaigns in real time or create one together with our team of professionals.',
  dashboardSubButton1String: 'Dashboard',
  dashboardSubTitle2String: 'New campaign',
  dashboardSubDescription2String:
    'Request a consultation with one of our accounts.',
  dashboardSubButton2String: 'Create campaign',
  rankitTitleString: 'What can you do with Rankit',
  rankitDescriptionString:
    'With Rankit you create your social and advocate marketing campaigns focusing on KPIs. Choose your goal, specify your audience, and let others talk about you. Then monitor your results based on the goals you have set.',
  rankitCpcDescriptionString: 'Generate qualified traffic',
  rankitCplDescriptionString: 'Generate qualified leads',
  rankitCpaDescriptionString: 'Generate qualified sales',
  rankitCpfDescriptionString: 'Generate qualified followers',
  rankitBrDescriptionString: 'Generate qualified articles',
  rankitInstagramTitleString: 'Instagram Analyze',
  rankitInstagramDescriptionString:
    'Discover our tool that allows you to analyze all the Instagram profiles you want',
  rankitTiktokTitleString: 'TikTok Analyze',
  rankitTiktokDescriptionString:
    'Discover our tool that allows you to analyze all the TikTok profiles you want.',
  rankitWatcherTitleString: 'Social watcher',
  rankitWatcherDescriptionString:
    "Trend topics change day by day and it is not easy to intercept the conversations and trend topics of the moment. At least, it wasn't until today. Discover our Social Watcher and create your marketing campaigns.",
  rankitCampaignTitle: 'Would you like to create a campaign?',
  rankitCampaignDescription:
    "Trend topics change day by day and it is not easy to intercept the conversations and trend topics of the moment. At least, it wasn't until today. Discover our Social Watcher and create your marketing campaigns.",
  rankitCampaignButtonString: 'Please contact me!',
  instagramAnalyzeTitle: 'Instagram Analyze',
  instagramAnalyzeDescription: 'Instagram metrics explorer',
  instagramAnalyzeFilterKeyword: 'Keyword',
  instagramAnalyzeFilterType: 'Type',
  instagramAnalyzeFilterTypeProfile: 'Profile',
  instagramAnalyzeFilterTypePost: 'Post',
  instagramAnalyzeFilterTypeHashtag: 'Hashtag',
  instagramAnalyzeFilterButtonApply: 'Apply',
  instagramAnalyzeFilterButtonCancel: 'Cancel',
  instagramAnalyzeResults: 'Results',
  tiktokAnalyzeResults: 'Results',
  tiktokAnalyzeFilterButtonCancel: 'Cancel',
  tiktokAnalyzeFilterButtonApply: 'Apply',
  tiktokAnalyzeFilterTypeHashtag: 'Hashtag',
  tiktokAnalyzeFilterTypePost: 'Post',
  tiktokAnalyzeFilterTypeProfile: 'Profile',
  tiktokAnalyzeFilterType: 'Type',
  tiktokAnalyzeFilterKeyword: 'Keyword',
  tiktokAnalyzeDescription: 'TikTok metrics explorer',
  tiktokAnalyzeTitle: 'TikTok Analyze',
  usernameString: 'Username',
  passwordString: 'Password',
  loginButtonString: 'Login',
  loadingString: 'Loading...',
  logoutButtonString: 'Logout',
  selectCampaignTitleString: 'Select campaign',
  selectCampaignAllString: 'All campaigns',
  selectCampaignKpiClickString: 'Total Clicks',
  selectCampaignKpiLeadString: 'Total Leads',
  selectCampaignTimeframeTodayString: 'Today',
  selectCampaignTimeframeYesterdayString: 'Yesterday',
  selectCampaignTimeframeLast7String: 'Last 7 days',
  selectCampaignTimeframeLast30String: 'Last 30 days',
  selectCampaignButtonCancelString: 'Cancel',
  selectCampaignButtonApplyString: 'Apply',
  summaryGroupString: 'Group',
  summaryTimeframeString: 'Timeframe',
  summaryKpiString: 'Kpi',
  downloadTitleString: 'Download report',
  downloadCsvTooltipString: 'Csv',
  downloadExcelTooltipString: 'Excel',
  downloadPdfTooltipString: 'Save as pdf',
  tableBrandString: 'Brand',
  tableCampaignString: 'Campaign',
  tableStartDateString: 'Start Date',
  tableEndDateString: 'End Date',
  tableTotalClickString: 'Total Clicks',
  tableTotalLeadString: 'Total Leads',
  tableTotalUserString: 'Unique Users',
  performanceTitleString: 'Overview performance',
  geoDistributionTitleString: 'Geo distribution',
  trafficSourceTitleString: 'Traffic sources',
  trafficSourceLabelClickString: 'Clicks',
  trafficSourceLabelLeadString: 'Leads',
  selectCampaignTimeframeCurrentMonthString: 'Current month',
  selectCampaignTimeframeLastMonthString: 'Last month',
  selectCampaignTimeframeCurrentYearString: 'Current year',
  selectCampaignTimeframeLastYearString: 'Last year',
  resultNoDataAvailable: 'No data available for this selection',
};
