import React from 'react';
import { BsLockFill } from 'react-icons/bs';

const maskStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '6px',
};

const DisplayResultsView = ({
  simulationResults,
  socials,
  themes,
  contentTypes,
}) => {
  const { result, simId } = simulationResults;

  const item = result[0];

  if (!item) {
    return <div>no data to display</div>;
  }

  console.log({ contentTypes });

  const socialLabel =
    socials.find(social => String(social.value) === String(item.social))
      ?.label || '';
  const themeLabel =
    themes.find(theme => String(theme.value) === String(item.theme))?.label ||
    '';
  const content =
    contentTypes.find(content => {
      return String(content.value) === String(item.contentType);
    })?.label || '';

  return (
    <>
      <h3 className="has-text-centered mb-4">La tua campagna</h3>
      <div className="columns is-multiline">
        <div className=" column is-4 has-text-centered p-4">
          <div className="box p-6">
            <span className="is-size-3 has-text-weight-bold">
              {item.isBudget ? item.result : item.target}
            </span>{' '}
            {item.kpi}
          </div>
        </div>
        <div className=" column is-4 has-text-centered p-4">
          <div className="box p-6">
            <span className="is-size-3 has-text-weight-bold">
              {item.isBudget ? item.budget : item.result}
            </span>{' '}
            euro
          </div>
        </div>
        <div className=" column is-4 has-text-centered p-4">
          <div className="box p-6">
            <span className="is-size-3 has-text-weight-bold">{item.reach}</span>
            <div>persone raggiunte</div>
          </div>
        </div>
        <div className=" column is-4 has-text-centered p-4 has-text-weight-bold">
          <div className="box p-3">{socialLabel}</div>
        </div>
        <div className=" column is-4 has-text-centered p-4 has-text-weight-bold">
          <div className="box p-3">{content}</div>
        </div>
        <div className=" column is-4 has-text-centered p-4 has-text-weight-bold">
          <div className="box p-3">{themeLabel}</div>
        </div>
      </div>
      <h4 className="has-text-centered mt-6 mb-4">
        Alcune community per il target selezionato
      </h4>
      <div className="columns is-multiline">
        {item.communities.length ? (
          item.communities.map((community, i) => (
            <div className="column is-4 has-text-centered p-4" key={i}>
              <div
                className="box  p-6 is-flex is-justify-content-center is-align-items-center"
                style={{ position: 'relative' }}
              >
                <img
                  src={community.image}
                  alt={community.title}
                  style={{
                    maxWidth: '100px',
                    borderRadius: '50%',
                    marginRight: '16px',
                  }}
                />{' '}
                <span className="has-text-weight-bold is-size-3">
                  {community.title}
                </span>
                {i > 2 ? (
                  <div style={maskStyle}>
                    <BsLockFill size="3rem" color="rgb(231 53 231)" />
                  </div>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <div>nessun risultato per il target selezionato</div>
        )}
      </div>
    </>
  );
};

export default DisplayResultsView;
