import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineBarChart, AiOutlineHeart } from 'react-icons/ai';
import { IoIosSpeedometer } from 'react-icons/io';
import { VscTools } from 'react-icons/vsc';
import { FiMonitor } from 'react-icons/fi';
import { BsGrid3X3, BsTable, BsGear } from 'react-icons/bs';

import { NavContext } from 'contexts/NavProvider';

const Wrapper = styled.nav`
  position: absolute;
  top: 92px;
  left: ${props => (props.open ? '0' : '-100%')};
  z-index: 31;
  padding: 8px 16px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all ease 0.25s;

  a {
    display: block;
    margin: 8px;
    padding: 8px;
  }
`;

const Nav = () => {
  const { open } = useContext(NavContext);

  return (
    <Wrapper open={open}>
      <Link
        to="/"
        className="has-tooltip-right has-tooltip-arrow"
        data-tooltip="Dashboard"
      >
        <span className="icon">
          <AiOutlineBarChart size="32px" />
        </span>
      </Link>
      <Link
        to="/campaign_simulator"
        className="has-tooltip-right has-tooltip-arrow"
        data-tooltip="Campaign simulator"
      >
        <span className="icon">
          <IoIosSpeedometer size="32px" />
        </span>
      </Link>
      {/* <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><BsGrid3X3 size="32px"/></span>  
      </Link>
      <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><FiMonitor size="32px"/></span>  
      </Link>
      <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><AiOutlineHeart size="32px"/></span>  
      </Link>
      <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><VscTools size="32px"/></span>  
      </Link>
      <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><BsTable size="32px"/></span>  
      </Link>
      <Link to="/" className="has-tooltip-right has-tooltip-arrow" data-tooltip="tooltip text">
        <span className="icon"><BsGear size="32px"/></span>  
      </Link> */}
    </Wrapper>
  );
};

export default Nav;
