import React from 'react';
import styled from 'styled-components';

import { useI18n } from 'contexts/I18nProvider';

const Wrapper = styled.div`
  _color: red;
  _font-weight: 500;
`;

const NoDataMessage = () => {
  const { messages } = useI18n();

  const text = messages.resultNoDataAvailable;

  return <Wrapper>{text}</Wrapper>;
};

export default NoDataMessage;
