// import {FaCloudShowersHeavy} from 'react-icons/fa'
import * as api from 'services/api';
import { types as typesChart } from 'store/reducers/charts.reducer';

export const types = {
  CAMPAIGN_CHART_0_REQUEST: 'CAMPAIGN_CHART_0_REQUEST',
  CAMPAIGN_CHART_2_REQUEST: 'CAMPAIGN_CHART_2_REQUEST',
  CAMPAIGN_CHART_5_REQUEST: 'CAMPAIGN_CHART_5_REQUEST',
};

export const campaignChartRequest = state => next => async action => {
  if (action.type === types.CAMPAIGN_CHART_0_REQUEST) {
    try {
      state.dispatch({
        type: typesChart.ON_CHART_0_STATUS,
        payload: { loading: true },
      });
      const responseData = await api.chart0(
        action.payload.params,
        action.payload.endpointsByRole
      );

      state.dispatch({
        type: typesChart.ON_CHART_0,
        payload: responseData,
      });

      state.dispatch({
        type: typesChart.ON_CHART_0_STATUS,
        payload: { loading: false },
      });
    } catch (error) {
      // console.log({error})
    }
  }
  if (action.type === 'ADMIN_STATS_REQUEST') {
    try {
      state.dispatch({
        type: 'ADMIN_STATS_STATUS',
        payload: { loading: true },
      });
      console.log('------->', action.payload);
      const responseData = await api.adminStats(
        action.payload.params,
        action.payload.timeframe /* il timeframe */
      );
      state.dispatch({
        type: 'ADMIN_STATS_READY',
        payload: responseData,
      });
      state.dispatch({
        type: 'ADMIN_STATS_STATUS',
        payload: { loading: false },
      });
    } catch (error) {
      console.log({ error });
    }
  }
  if (action.type === types.CAMPAIGN_CHART_2_REQUEST) {
    try {
      state.dispatch({
        type: typesChart.ON_CHART_2_STATUS,
        payload: { loading: true },
      });
      const responseData = await api.chart2(
        action.payload.params,
        action.payload.endpointsByRole
      );

      state.dispatch({
        type: typesChart.ON_CHART_2,
        payload: responseData,
      });

      state.dispatch({
        type: typesChart.ON_CHART_2_STATUS,
        payload: { loading: false },
      });
    } catch (error) {
      // console.log({error})
    }
  }
  if (action.type === types.CAMPAIGN_CHART_5_REQUEST) {
    try {
      state.dispatch({
        type: typesChart.ON_CHART_5_STATUS,
        payload: { loading: true },
      });
      const responseData = await api.chart5(
        action.payload.params,
        action.payload.endpointsByRole
      );

      state.dispatch({
        type: typesChart.ON_CHART_5,
        payload: responseData,
      });

      state.dispatch({
        type: typesChart.ON_CHART_5_STATUS,
        payload: { loading: false },
      });
    } catch (error) {
      // console.log({error})
    }
  }
  next(action);
};
