import * as React from 'react';
import { isNil } from 'lodash';
// import {IntlProvider} from 'rsuite'
// import itIT from 'rsuite/lib/IntlProvider/locales/it_IT'
// import enGB from 'rsuite/lib/IntlProvider/locales/en_GB'

import {
  // RANKIT_APP_USER_LANG_DATA,
  // RANKIT_APP_USER_LANG_VERSION,
  RANKIT_APP_USER_LANG,
  TIMEFRAME_DATE_FORMAT,
  TIMEFRAME_DATE_FORMAT_EN,
} from 'utils/config';
import defaultMessages from 'utils/defaultMessagesEn';
// import useLocalStorageState from 'hooks/useLocalStorageState'
import * as api from 'services/api';
import { useSession } from './SessionProvider';
import defaultMessagesEn from 'utils/defaultMessagesEn';

// async function loadLocaleMessages(locale) {
//   switch(locale) {
//     case 'en':
//       return import('compiled-lang/en.json')
//     default:
//       return import('compiled-lang/it.json')
//   }
// }

const langMap = {
  italian: 'it',
  english: 'en',
};

const metric = ['italian'];

// const dateRangeLocaleEn ={
//   sunday: 'Su',
//   monday: 'Mo',
//   tuesday: 'Tu',
//   wednesday: 'We',
//   thursday: 'Th',
//   friday: 'Fr',
//   saturday: 'Sa',
//   ok: 'OK',
//   today: 'Today',
//   yesterday: 'Yesterday',
//   last7Days: 'Last 7 days'
// }

// const dateRangeLocaleIt ={
//   sunday: 'Do',
//   monday: 'Lu',
//   tuesday: 'Ma',
//   wednesday: 'Me',
//   thursday: 'Gi',
//   friday: 'Ve',
//   saturday: 'Sa',
//   ok: 'OK',
//   today: 'Oggi',
//   yesterday: 'Ieri',
//   last7Days: 'Ultimi 7 giorni'
// }

// function formatMessages(loadedMessages) {
//   // from remote format to compiled-lang format
//   let messages = {}
//   forEach(loadedMessages, (value, key) => {
//     messages[key] = [{type: 0, value: value}]
//   })
//   console.log({messages})
//   return messages
// }

// const formattedDefaultMessages = formatMessages(defaultMessages)

export const I18nContext = React.createContext({
  messages: {},
  defaultMessages,
  locale: 'en',
  dateFormat: TIMEFRAME_DATE_FORMAT_EN,
  currencySymbol: '$',
});

const I18nProvider = ({ children }) => {
  const { user } = useSession();
  const [pagination, setPagination] = React.useState(6);
  const [messages, setMessages] = React.useState(() => {
    if (
      isNil(user) &&
      !isNil(window.localStorage.getItem(RANKIT_APP_USER_LANG))
    ) {
      const lang = window.localStorage.getItem(RANKIT_APP_USER_LANG);
      return metric.includes(lang) ? defaultMessages : defaultMessagesEn;
    }
    return defaultMessagesEn;
  });
  // const [loadedMessages, setLoadedMessages] = React.useState()
  // const [userLang] = useLocalStorageState(RANKIT_APP_USER_LANG)
  // const [userLangVersion] = useLocalStorageState(RANKIT_APP_USER_LANG_VERSION)
  // const [userLangData, setUserLangData] = useLocalStorageState(RANKIT_APP_USER_LANG_DATA)
  // const [rsuiteLocale, setRsuiteLocale] = useLocalStorageState('rsuiteLocale', enGB)

  // const defaultLocale = 'en'

  // let rsuiteLocale = enGB

  /**
   * on mount check if language info are in localstorage
   */
  // React.useEffect(() => {
  //   if(userLang && userLangVersion && userLangData) {
  //     // search in localstorage ...
  //     // console.log('on mount: loading --- local storage')
  //     setMessages(userLangData)
  //   }
  // }, [])

  // React.useEffect(() => {

  //
  // }, [userLang])

  React.useEffect(() => {
    // console.log('before isNil', user)
    if (isNil(user)) {
      if (!isNil(window.localStorage.getItem(RANKIT_APP_USER_LANG))) {
        const lang = window.localStorage.getItem(RANKIT_APP_USER_LANG);
        return metric.includes(lang) ? defaultMessages : defaultMessagesEn;
      }
      return defaultMessagesEn;
    }

    async function loadLang() {
      const lang = await api.getLang(`lang=${user.lang}`);
      console.log('lang', lang);
      //setUserLangData(lang.data)
      setMessages(lang.data);
      setPagination(lang.pagination);
      // setRsuiteLocale(user.lang === 'italian' ? itIT : enGB)
      // console.log({user, userLang, userLangVersion})
    }

    loadLang();
    // if(userLang !== user.lang && userLangVersion !== user.lang_version) {
    //   loadLang()
    // }

    // if(userLang === user.lang && userLangVersion === user.lang_version) {
    //   // search in localstorage ...
    //   // console.log('on user: loading --- local storage')
    //   // setLoadedMessages(userLangData)
    // } else {
    //   // ... or load from remote ...
    //   loadLang()
    // }
  }, [user]);

  const contextValue = {
    pagination,
    messages,
    defaultMessages,
    locale: user ? langMap[user.lang] : 'en',
    dateFormat: user
      ? metric.includes(user.lang)
        ? TIMEFRAME_DATE_FORMAT
        : TIMEFRAME_DATE_FORMAT_EN
      : TIMEFRAME_DATE_FORMAT_EN,
    currencySymbol: user ? (metric.includes(user.lang) ? '€' : '$') : '$',
    // NOTE:  DateRangePicker has a different format string
    // dateRangeLocale: metric.includes(userLang) ? dateRangeLocaleIt : dateRangeLocaleEn,
    // dateRangeFormat: metric.includes(userLang) ? 'DD-MM-YYYY' : 'MM-DD-YYYY',
  };

  return (
    <I18nContext.Provider value={contextValue}>
      {/* <IntlProvider locale={rsuiteLocale}> */}
      {children}
      {/* </IntlProvider> */}
    </I18nContext.Provider>
  );
};

export default I18nProvider;

export function useI18n() {
  const ctx = React.useContext(I18nContext);
  return ctx;
}
