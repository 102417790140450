import React, {useContext} from 'react'
import {CampaignFiltersContext} from 'contexts/CampaignFiltersProvider'
import {useI18n} from 'contexts/I18nProvider'

const FiltersInfo = () => {
  const {messages} = useI18n()

  const {info} = useContext(CampaignFiltersContext)
  return (
    <div>
      <strong>{messages.summaryGroupString}: </strong>: {info.group} - <strong>{messages.summaryTimeframeString}</strong>: {info.timeframe} - <strong>KPI</strong>: {info.kpi}
    </div>
  )
}

export default FiltersInfo
