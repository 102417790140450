export const RANKIT_APP_USER = 'rankit-app-user';
export const RANKIT_APP_USER_LANG = 'rankit-app-user-lang';
export const RANKIT_APP_USER_LANG_VERSION = 'rankit-app-user-lang-version';
export const RANKIT_APP_USER_LANG_DATA = 'rankit-app-user-lang-data';
export const RANKIT_APP_TOKEN = 'rankit-app-token';
export const TIMEFRAME_DATE_FORMAT = 'dd/M/yyyy';
export const TIMEFRAME_DATE_FORMAT_EN = 'M/dd/yyyy';
export const TIMEFRAME_DATE_FORMAT_FOR_URL_PARAMS = 'yyy-M-dd';
export const TIMEFRAME_DATE_FORMAT_FOR_URL_PARAMS_EN = 'yyy-dd-M';
export const TOKEN_POLL_INTERVAL = 60 * 1000 * 10; // 10 minutes
